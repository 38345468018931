export default [
  {
    'elementType': `geometry`,
    'stylers': [
      { 'color': `#0a1c23` },
    ],
  },
  {
    'elementType': `geometry.fill`,
    'stylers': [
      { 'color': `#0a1c23` },
    ],
  },
  {
    'elementType': `geometry.stroke`,
    'stylers': [
      { 'color': `#0a1c23` },
    ],
  },
  {
    'elementType': `labels.icon`,
    'stylers': [
      { 'visibility': `off` },
    ],
  },
  {
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `white` },
    ],
  },
  {
    'elementType': `labels.text.stroke`,
    'stylers': [
      { 'color': `#212121` },
    ],
  },
  {
    'featureType': `administrative`,
    'elementType': `geometry`,
    'stylers': [
      { 'color': `#757575` },
    ],
  },
  {
    'featureType': `administrative.country`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#9e9e9e` },
    ],
  },
  {
    'featureType': `administrative.land_parcel`,
    'stylers': [
      { 'visibility': `off` },
    ],
  },
  {
    'featureType': `administrative.locality`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#bdbdbd` },
    ],
  },
  {
    'featureType': `poi`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#757575` },
    ],
  },
  {
    'featureType': `poi.park`,
    'elementType': `geometry`,
    'stylers': [
      { 'color': `#181818` },
    ],
  },
  {
    'featureType': `poi.park`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#616161` },
    ],
  },
  {
    'featureType': `poi.park`,
    'elementType': `labels.text.stroke`,
    'stylers': [
      { 'color': `#1b1b1b` },
    ],
  },
  {
    'featureType': `road`,
    'elementType': `geometry.fill`,
    'stylers': [
      { 'color': `#4b5f65` },
    ],
  },
  {
    'featureType': `road`,
    'elementType': `geometry.stroke`,
    'stylers': [
      { 'color': `#4b5f65` },
    ],
  },
  {
    'featureType': `road`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#8a8a8a` },
    ],
  },
  {
    'featureType': `road.arterial`,
    'elementType': `geometry`,
    'stylers': [
      { 'color': `#4b5f65` },
    ],
  },
  {
    'featureType': `road.arterial`,
    'elementType': `geometry.fill`,
    'stylers': [
      { 'color': `#4b5f65` },
    ],
  },
  {
    'featureType': `road.arterial`,
    'elementType': `geometry.stroke`,
    'stylers': [
      { 'color': `#4b5f65` },
    ],
  },
  {
    'featureType': `road.highway`,
    'elementType': `geometry`,
    'stylers': [
      { 'color': `#3c3c3c` },
    ],
  },
  {
    'featureType': `road.highway`,
    'elementType': `geometry.fill`,
    'stylers': [
      { 'color': `#6b7d83` },
    ],
  },
  {
    'featureType': `road.highway`,
    'elementType': `geometry.stroke`,
    'stylers': [
      { 'color': `#6b7d83` },
    ],
  },
  {
    'featureType': `road.highway.controlled_access`,
    'elementType': `geometry`,
    'stylers': [
      { 'color': `#4e4e4e` },
    ],
  },
  {
    'featureType': `road.local`,
    'elementType': `geometry.fill`,
    'stylers': [
      { 'color': `#4b5e65` },
    ],
  },
  {
    'featureType': `road.local`,
    'elementType': `geometry.stroke`,
    'stylers': [
      { 'color': `#4b5a65` },
    ],
  },
  {
    'featureType': `road.local`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#8a8a8a` },
    ],
  },
  {
    'featureType': `transit`,
    'elementType': `geometry.fill`,
    'stylers': [
      { 'color': `#6b7d83` },
    ],
  },
  {
    'featureType': `transit`,
    'elementType': `geometry.stroke`,
    'stylers': [
      { 'color': `#6b7d83` },
    ],
  },
  {
    'featureType': `transit`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#757575` },
    ],
  },
  {
    'featureType': `water`,
    'elementType': `geometry`,
    'stylers': [
      { 'color': `#000000` },
    ],
  },
  {
    'featureType': `water`,
    'elementType': `labels.text.fill`,
    'stylers': [
      { 'color': `#3d3d3d` },
    ],
  },
]
