import styled from 'styled-components'
import { useConfig } from '../hooks/useConfig'
import { useRobotId } from '../hooks/useRobotId'
import { ChartProvider } from './ChartProvider'
import { ChartsModal } from './ChartsModal'

export const Charts = () => {
  const [robotId] = useRobotId()
  const [config] = useConfig()
  const { plots } = config.fleet_status

  if(!robotId) return <Div>Please select a robot to see chart data</Div>
  return (
    <Container>
      <Row>
        <ChartProvider number={1} options={plots.row_0.plot_0}/>
        <ChartProvider number={2} options={plots.row_0.plot_1}/>
      </Row>
      <Row>
        <ChartProvider number={3} options={plots.row_1.plot_0}/>
        <ChartProvider number={4} options={plots.row_1.plot_1}/>
      </Row>
      <ChartsModal/>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
  height:80vh;
  flex-grow:1;
`
const Row = styled.div`
  display:flex;
  flex-direction:row;
  width:100%;
  height:40vh;
`
const Div = styled.div`
  padding-top:10vh;
  color:white;
  font-size:2rem;
`
