import { useRobotId } from './useRobotId'
import { atom,useAtom } from 'jotai'
import { useEffect } from 'react'
import { useLatestData } from './useLatestData'

let alreadyShown:string[] = []

export const alertAtom = atom<any[]>([])

export const useAlerts = () => {
  const [robotId] = useRobotId()
  const { data } = useLatestData(robotId)
  const [alerts,setAlerts] = useAtom(alertAtom)

  useEffect(() => {
    setAlerts([])
  },[robotId])

  useEffect(() => {
    const alert = data?.readings?.find(r => r.type === `robot_diagnostics`)
    const { level,message } = alert?.data || { level: 0,message: `` }
    if([1,2].includes(level))
      if(!alreadyShown.includes(message)){
        alreadyShown.push(message)
        setAlerts(s => ([...s,alert]))
        setTimeout(() => {
          alreadyShown = alreadyShown
            .filter(a => a !== message)
        },20 * 1000)
      }
  },[data])

  return [alerts,setAlerts]
}
