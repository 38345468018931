//@ts-nocheck
import styled from 'styled-components'
import { Robot } from '../api'
import { useRobots } from '../hooks/useRobots'
import { RobotMarker } from './RobotMarker'

export const RobotLayer = () => {
  const { data: robots = [] } = useRobots()
  return (
    <Container>
      {robots?.results?.map((robot:Robot,index) => {
        return (
          <RobotMarker key={index} robot={robot}/>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`
