import { useControlAllocator } from '../hooks/useControlAllocator'
import { CameraSelector } from './CameraSelector'
import { ChartTools } from './ChartTools'
import { ControlAllocator } from './ControlAllocator'
import { Crosshairs } from './Crosshairs'
import { DraggableMainMenu } from './DraggableMainMenu'
import { EmergencyStop } from './EmergencyStop'
import { FullScreenMode } from './FullScreenMode'
import { Notification } from './Notification'
import { Joystick } from './Joystick'
import { Legend } from './Legend'
import { MapContainer } from './MapContainer'
import { Map } from './Map'
import { Metrics } from './Metrics'
import { RouteTools } from './RouteTools'
import { ShowHideCamera } from './ShowHideCamera'
import { StartMisson } from './StartMission'
import { useRobotId } from '../hooks/useRobotId'
import { ViewManager } from './ViewManager'
import { useLoadViewConfig } from '../hooks/useLoadViewConfig'

export const MapView = () => {
  const [controlledRobotId] = useControlAllocator()
  const [robotId] = useRobotId()
  const ready = useLoadViewConfig()
  if(!ready) return null
  return (
    <>
      {/* <Legend/> */}
      {/* <JanusText/> */}
      {/* <Crosshairs/> */}
      {/* <StartMisson /> */}

      <Map/>
      <MapContainer/>
      <ChartTools/>      
      <RouteTools/>      
      <Metrics/>
      <DraggableMainMenu/>
      <Notification/>
      <ShowHideCamera/>
      {robotId && controlledRobotId === robotId && <Joystick/>}
      <ControlAllocator/>
      <ViewManager/>
      <FullScreenMode/>
      <EmergencyStop/>      
    </>
  )
}

