//@ts-nocheck
import { useEffect,useState } from 'react'
import adapter from 'webrtc-adapter' 

window[`adapter`] = adapter

let janus:any
const server = process.env.REACT_APP_JANUS_SERVER
const iceServers = null
let janusStatus = `idle`

export const useJanus = () => {
  const [status,setStatus] = useState(`idle`)
  useEffect(() => {
    setInterval(() => {
      if(status !== janusStatus) setStatus(janusStatus)
    },1000)
    if(janusStatus === `idle`){
      janusStatus = `connecting`
      Janus.init({
        debug: `all`,
        callback() {
          if(!Janus.isWebrtcSupported()) return window.alert(`No WebRTC support... `)  
          janus = new Janus({
            server,
            iceServers,
            // Should the Janus API require authentication, you can specify either the API secret or user token here too
            //		token: "mytoken",
            //	or
            //		apisecret: "serversecret",
            success() { janusStatus = `connected` },
            error(error) { janusStatus = `failed` },
            destroyed() { janusStatus = `destroyed` },
          })
        },
      }) 
    }
  },[])
  return [janusStatus,janus]
}
