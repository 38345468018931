
interface Waypoint {
  lat:number
  lng:number
}

export const waypointsFromArray = (array:Waypoint[]) => {
  const output: { [index: string]: Waypoint; } = {}

  array.forEach((waypoint,index:number) => {
    output[index + 1] = waypoint
  })

  return output
}
