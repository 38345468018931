import { useChartTools } from '../hooks/useChartTools'
import { useOdometryOverlay } from '../hooks/useOdometryOverlay'
import { Marker } from './Marker'
import { OdometryOverlayPolyline } from './OdometryOverlayPolyline'

export const OdometryOverlayLayer = () => {
  const [chartTools] = useChartTools()
  const path = useOdometryOverlay() 

  if(!chartTools[2].active) return null
  return (
    <>
      <OdometryOverlayPolyline path={path}/>
      {path.map((waypoint,index) => {
        return (
          <Marker 
            key={index}
            number={index}
            waypoint={waypoint}/>
        )
      })}
    </>
  )
}

