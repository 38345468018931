
interface Waypoint {
  lat:number
  lng:number
}

export const arrayFromWaypoints = (waypoints:any = []) => {
  const path:Waypoint[] = []

  Object.keys(waypoints).forEach((key:string) => {
    path.push(waypoints[key])
  })
  return path
}
