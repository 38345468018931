import { useEffect,useState } from 'react'
import styled from 'styled-components'
import ViewOptionIconSVG from '../icons/ViewOption.svg'
import ChevronIconSVG from '../icons/ChevronDown.svg'
import fullscreenIconSVG from '../icons/Fullscreen.svg'
import mapSVG from '../icons/Map.svg'
import cameraSVG from '../icons/Camera.svg'
import ActiveChevronIconSVG from '../icons/ChevronDownActive.svg'
import CameraIconSVG from '../icons/Camera.svg'
import CameraIcon from './CameraIcon'
import DraggableDialog from './DraggableDialog'
import { Button } from './Button'
import { Card } from './Card'
import { useVideoroomPublisher } from '../hooks/useVideoroomPublisher'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { useVideoroomSubscriber } from '../hooks/useVideoroomSubscriber'
import { Video } from './video'
import { MiniMap } from './MiniMap'


export interface CameraSelectorProps {
  css?: string
}

export function CameraSelector({ css }: CameraSelectorProps):JSX.Element {
  const [fullScreenMode,setFullScreenMode] = useFullScreenMode()
  useVideoroomPublisher()
  useVideoroomSubscriber()
  const cameras = [1]
  const [activeButton,setActiveButton] = useState(``)

  const handleCameraClick = (id:string) => { 
    setActiveButton(id)
  }

  const handleViewClick = () => { 
    setActiveButton(`view`)
    console.log(`view`)
  }

  const handleFullscreenClick = () => { 
    setFullScreenMode(!fullScreenMode)
  }

  return (
    <Container>
      <Header>
        {/* <Cameras>
              {cameras.map((item,index) => {
                const name = index + 1
                const id = name.toString()
          
                return ( 
                  <CameraButton 
                    key={id} 
                    onClick={() => handleCameraClick(id)}
                    active={activeButton === id}>
                    <CameraIcon active={activeButton === id}/>
                    <CameraNumber active={activeButton === id}>{id}</CameraNumber>
                  </CameraButton>
                )
              })}
            </Cameras> */}
        {/* <View>
              <ViewButton>
                <ViewOptionIcon src={ViewOptionIconSVG} />
                <ViewLabel>1 View</ViewLabel>
              </ViewButton>
              <ChevronButton activeButton={activeButton} onClick={handleViewClick}>
                <ChevronIcon src={activeButton === `view` ? ActiveChevronIconSVG : ChevronIconSVG} />
              </ChevronButton>
            </View> */}
        <Fullscreen>
          <FullscreenButton onClick={handleFullscreenClick}>
            <FullscreenIcon src={fullScreenMode ? cameraSVG : mapSVG} />
          </FullscreenButton>
        </Fullscreen>
      </Header>
      {/* <ViewFinder id="viewFinder"></ViewFinder> */}
      {!fullScreenMode && <Video/>}
      {fullScreenMode && <MiniMap/>}
    </Container>
  )
}

const Header = styled.div`
  background-color:rgba(7, 34, 42, 0.9);
  display: flex;
  height: 65px;
  justify-content:flex-end;
  border-bottom: 1px solid #71aabbca;
`
const Cameras = styled.div`
  display: flex;
  width: 45%;
  height: 100%;
  `
const Container = styled.div`
  width:400px;
`
const CameraButton = styled.div<{active:boolean}>`
  background-color: ${p => p.active ? `rgba(157, 228, 250, 0.884)` : `rgba(3, 23, 29, 0.95)`};
  border-bottom: ${p => p.active ? `1px solid #71aabbca` : ``};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25%;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${p => p.active ? `` : `rgba(7, 34, 42, 0.9)`};
  }
`
const CameraNumber = styled.p<{active:boolean}>`
  position: absolute;
  margin: 0;
  font-size: .7rem;
  left: 17px;
  top: px;
  font-weight: 600;
  color: ${p => p.active ? `#233C43` : ``}
`
const View = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
  width:35%;
`
const ViewButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 0 .5rem;
  width: 70%;
  background-color: rgba(3, 23, 29, 0.95);
  /* cursor: pointer; */
  &:hover {
    /* background-color: rgba(7, 34, 42, 0.95); */
  }
`
const ViewLabel = styled.p`
  margin: 0;
  font-size: .9rem;
  font-weight: 600;
  cursor: default;
`
const ViewOptionIcon = styled.img`
  width: 20px;
  margin-right: .25rem;
`
const ChevronButton = styled.div<{activeButton:string}>`
  display: flex;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  width: 30%;
  cursor: pointer;
  
  ${({ activeButton }) => activeButton === `view` ? 
    `background-color:rgba(157, 228, 250, 0.884);  border-bottom: 1px solid #71aabb;` : 
    `background-color:rgba(3, 23, 29, 0.95);&:hover {background-color: rgba(7, 34, 42, 0.95);}`};
  
`
const ChevronIcon = styled.img`
  width: 25px;
`
const Fullscreen = styled.div`
    display: flex;
  justify-content: stretch;
  align-items: stretch;
  text-align: center;
  width: 20%;
`
const FullscreenButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border-left: 1px solid rgba(85, 117, 127, 0.2);
  background-color: rgba(3, 32, 39, 0.97);
  cursor: pointer;
  &:hover {
    background-color: rgba(4, 35, 43, 0.97);
  }
`
const FullscreenIcon = styled.img`
  width: 25px;
`
const ViewFinder = styled.div`
  height: 400px;
  width:100%;

  background-image: url('https://gcdnb.pbrd.co/images/p3I0Exc6ZBs6.png?o=1');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
`
