import DraggableDialog from './DraggableDialog'
import { Menu } from './Menu'
import { useChartTools } from '../hooks/useChartTools'
import { useRouteTools } from '../hooks/useRouteTools'
import { usePath } from '../hooks/usePath'
import axios from 'axios'
import { useRobotId } from '../hooks/useRobotId'
import { waypointsFromArray } from '../utils/waypointsFromArray'
import { useNotification } from '../hooks/useNotification'
import { useMissionStatus } from '../hooks/useMissionStatus'
import { useEffect } from 'react'
import { useOccupancyGridRobotId } from '../hooks/useOccupancyGridId'

export function RouteTools():JSX.Element|null {
  const [robotId] = useRobotId()
  const [path,setPath] = usePath()
  const [chartToolItems] = useChartTools()
  const [items,setItems] = useRouteTools()
  const [,setNotification] = useNotification()
  const [occupancyGridRobotId] = useOccupancyGridRobotId()
  //TODO try to get status dispatched notification
  // const { data: missionStatus } = useMissionStatus()

  // const status = missionStatus?.data?.status || `-`
  // useEffect(() => {
  //   if(status === `dispatched`) setNotification(`Route dispatched`)
  // },[status])

  const options:any[] = [...items]
  options[0].onClick = () => {
    setItems(items.map(item => {
      return {
        ...item,
        active: item.id === 0 ? !item.active : item.active,
      }
    }))
  }
  options[0].label = options[0].active ? `Hide Route Planner` : `Show Route Planner`
  options[1].enabled = path.length > 1
  options[1].onClick = async() => {
    if(path.length < 2) return
    const data = {
      type: `Mission`,
      version: 1,
      robot: robotId,
      data: { 
        status: `pending`,
        indoor: !!occupancyGridRobotId,
        waypoints: waypointsFromArray(path), 
      },
    }
    setNotification(`Uploading Route...`)
    axios({
      method: `post`,
      url: process.env.REACT_APP_API_CLIENT_BASE + `/mission/create`,
      data,
      headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
    })
      .then((res:any) => {
        console.log(res)
        //TODO try to get status dispatched notification
        // const id = res?.data?.id
        // console.log({ id })
        // setInterval(() => {
        //   axios({
        //     method: `get`,
        //     url: process.env.REACT_APP_API_CLIENT_BASE + `/mission/status/${id}`,
        //     headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
        //   }).then(res => console.log(res?.data?.status))
        // },500)

      })
      .catch(console.log)
    // setPath([])
  }
  options[2].onClick = () => setPath([]) 
  options[2].enabled = true

  if(!chartToolItems[0].active) return null

  return (
    <DraggableDialog widget="route_tools_widget">
      <Menu items={options}/>
    </DraggableDialog>
  )
}
