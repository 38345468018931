export default [
  {
    "stylers": [
      { "color": `#0e1c29` },
      { "visibility": `simplified` },
    ],
  },
  {
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "elementType": `geometry.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "elementType": `labels`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "elementType": `labels.icon`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "elementType": `labels.text.stroke`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `administrative`,
    "elementType": `geometry`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "featureType": `administrative.land_parcel`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "featureType": `administrative.land_parcel`,
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `administrative.neighborhood`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "featureType": `poi`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "featureType": `poi`,
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `poi`,
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `poi.park`,
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `poi.park`,
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `road`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "featureType": `road`,
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `road`,
    "elementType": `labels.icon`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "featureType": `road.arterial`,
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `road.highway`,
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `road.highway`,
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `road.local`,
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `transit`,
    "stylers": [
      { "visibility": `off` },
    ],
  },
  {
    "featureType": `transit.line`,
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `transit.station`,
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `water`,
    "elementType": `geometry`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
  {
    "featureType": `water`,
    "elementType": `labels.text.fill`,
    "stylers": [
      { "color": `#0e1c29` },
    ],
  },
]
