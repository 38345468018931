import { useEffect,useRef } from 'react'
import styled from 'styled-components'
import { useTextroom } from '../hooks/useTextroom'
import { useRoomId } from '../hooks/useRoomId'
import joystick from '../icons/Group 327.svg'
import { joystickPayload } from '../utils/joystick.formatter'
import { randomString } from '../utils/randomString'
import DraggableDialog from './DraggableDialog'
import { VisualFeedback } from './VisualFeedback'
import { Title } from './Menu'

let x = 0
let y = 0
let mouseDown = false

export const Joystick = () => {
  const ref = useRef<HTMLImageElement>()
  const [textroomId] = useRoomId()
  const { status,textroom } = useTextroom()

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log({ x,y })

      if(status === `joined`)
        textroom?.data?.({
          text: JSON.stringify({
            textroom: `message`,
            transaction: randomString(12),
            room: textroomId,
            text: joystickPayload({ x,y }),
          }),
          error(reason:string) { console.log(`could not send to textroom`,reason) },
          success(){},
        })

    },1000 / 30)
    return () => clearInterval(interval)
  },[status,textroom])

  const handleMouseDown = (e:any) => {
    e.stopPropagation()
    mouseDown = true
    calculateXY(e)
  }
  const handleMouseUp = (e:any) => {
    e.stopPropagation()
    mouseDown = false
    setTimeout(() => {
      x = 0
      y = 0    
    },0)
  }

  const handleMouseOut = (e:any) => {
    mouseDown = false
    setTimeout(() => {
      x = 0
      y = 0    
    },0)
  }

  const handleMouseMove = (e:any) => {
    if(!mouseDown) return
    calculateXY(e)
  }

  const calculateXY = (e:any) => {
    const boundingRect = ref.current?.getBoundingClientRect()
    const inputX = (e.pageX - (boundingRect?.left || 0)) / (boundingRect?.width || 1) * 2 - 1
    const inputY = - ((e.pageY - (boundingRect?.top || 0)) / (boundingRect?.height || 1) * 2 - 1)
    const amplitude = Math.sqrt(inputX * inputX + inputY * inputY)
    x = inputX * 1 / (Math.max(1,amplitude))
    y = inputY * 1 / (Math.max(1,amplitude))
  }

  useEffect(() => {
    window.addEventListener(`mouseup`,handleMouseOut)
    return () => window.removeEventListener(`mouseup`,handleMouseOut)
  })

  useEffect(() => {
    window.addEventListener(`mousemove`,handleMouseMove)
    return () => window.removeEventListener(`mousemove`,handleMouseMove)
  })

  return (
    <DraggableDialog 
      widget="joystick_widget">
      <Container id="joystick-wrapper">
        <Title>Joystick Control</Title>
        <Absolute
          onMouseDown={handleMouseDown}
          // onMouseOut={handleMouseOut}
          onMouseUp={handleMouseUp}>
          <Img ref={ref} src={joystick}/>
          <VisualFeedback/>
        </Absolute>
      </Container>
    </DraggableDialog>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
  gap:20px;
  padding:16px;
  z-index: 1;
  cursor: move;
  background: rgb(3, 22, 28, 0.8);
  color: #fff;
  width: 260px;
  display:flex;
  justify-content:center;
  align-items:center;
  min-width: 250px;
  min-height: 280px;
  box-sizing:border-box;
`
const Absolute = styled.div`
  /* position:relative; */
  border-radius:50%;
  width:220px;
  height:220px;
  cursor:pointer;
  display: grid;
  place-items: center;
  grid-template-areas: "inner-div";
`
const Img = styled.img<{ref:any}>`
  left:0px;
  top:0px;
  cursor:pointer;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  border-radius:50%;
  /* position:absolute; */
  pointer-events: initial;
  grid-area: inner-div;
`
