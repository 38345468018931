/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LargeRobotData } from '../models/LargeRobotData';
import type { ReadRobotData } from '../models/ReadRobotData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RobotDataService {

    /**
     * @param robotId
     * @returns ReadRobotData
     * @throws ApiError
     */
    public static latestData(
        robotId: string,
    ): CancelablePromise<ReadRobotData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/robot-data/latest/{robot_id}/',
            path: {
                'robot_id': robotId,
            },
        });
    }

    /**
     * @param dataType
     * @param robotId
     * @returns ReadRobotData
     * @throws ApiError
     */
    public static latestTypedData(
        dataType: string,
        robotId: string,
    ): CancelablePromise<ReadRobotData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/robot-data/latest/{robot_id}/{data_type}',
            path: {
                'data_type': dataType,
                'robot_id': robotId,
            },
        });
    }

    /**
     * @param robotId
     * @param page A page number within the paginated result set.
     * @returns any
     * @throws ApiError
     */
    public static allRobotData(
        robotId: string,
        page?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<LargeRobotData>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/robot-data/{robot_id}/',
            path: {
                'robot_id': robotId,
            },
            query: {
                'page': page,
            },
        });
    }

    /**
     * @param dataType
     * @param robotId
     * @param page A page number within the paginated result set.
     * @returns any
     * @throws ApiError
     */
    public static allRobotDataByType(
        dataType: string,
        robotId: string,
        page?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<LargeRobotData>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/robot-data/{robot_id}/{data_type}',
            path: {
                'data_type': dataType,
                'robot_id': robotId,
            },
            query: {
                'page': page,
            },
        });
    }

}
