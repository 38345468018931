import { ReactNode,useEffect,useState } from 'react'
import { OpenAPI } from '../api'
import { useAuth } from '../hooks/useAuth'

function parseJwt(token:string) {
  var base64Url = token.split(`.`)[1]
  var base64 = base64Url.replace(/-/g,`+`).replace(/_/g,`/`)
  var jsonPayload = decodeURIComponent(window.atob(base64).split(``).map(function(c) {
    return `%` + (`00` + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(``))

  return JSON.parse(jsonPayload)
}

export const AutoLogin = ({ children }:{children:ReactNode}) => {
  const [ready,setReady] = useState(false)
  const [auth,setAuth] = useAuth()
  OpenAPI.BASE = process.env.REACT_APP_API_CLIENT_BASE as string
  useEffect(() => {
    const token = localStorage.getItem(`jwt`)
    if(token){ 
      const { exp } = parseJwt(token)
      const expDate = new Date(exp * 1000)
      const now = new Date()
      const milliseconds = expDate.getTime() - now.getTime()
      if(milliseconds < 0) localStorage.removeItem(`jwt`)
      else{
        if(auth.token !== token)setAuth({ token })
        OpenAPI.TOKEN = token
        setTimeout(() => {
          console.log(`need to get new token`)        
          localStorage.removeItem(`jwt`)
          setAuth({ token: `` })
        },milliseconds)
      }
    }
    if(!ready)setReady(true)
  },[auth])
  if(!ready) return null
  return <>{children}</>
}

