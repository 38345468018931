import styled from 'styled-components'
import { Button } from './Button'
import CloseIconSVG from '../icons/Close.svg'
import { useAlerts } from '../hooks/useAlerts'
import alertIcon from '../icons/Alerts.svg'
import { useEffect,useState } from 'react'


export interface ModalProps {
  css?: string
  label?:string
  open?:boolean
}

export function AlertModal({ css }: ModalProps):JSX.Element {
  const [alerts] = useAlerts() as [any[]]
  const [state,setState] = useState(``)

  const handleClose = () => { 
    setState(``)
  }

  useEffect(() => {
    if(alerts.length){
      const alert = [...alerts][alerts.length - 1]
      const { message } = alert?.data as any
      setState(message)
    }
  },[alerts])
  
  return (
    <>
      {state && 
      <Container css={css}>
        <StyledModal>
          <Header>
            <TitleWrapper>
              <AlertIcon 
                src={alertIcon}
                onClick={handleClose}/>
              <Title>Alert</Title>
            </TitleWrapper>
            <CloseIcon  
              onClick={handleClose}
              src={CloseIconSVG}/>
          </Header>
          <Content>
            {/* <MetricWrapper>
              <Metric name={alert.metric || ``}></Metric>
            </MetricWrapper> */}
            <Description>{state || ``}</Description>
            <ButtonContainer>
              {/* <ButtonWrapper>
                <Button 
                  border 
                  onClick={handleClose}>{alert.confirmText}</Button>
              </ButtonWrapper> */}
              <ButtonWrapper>
                <Button 
                  onClick={handleClose}
                  border 
                  alert>Dismiss alert</Button>
              </ButtonWrapper>
            </ButtonContainer>
          </Content>

        </StyledModal>
      </Container>
      }
    </>
  )
}

const Container = styled.div<ModalProps>`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(51, 85, 95, 0.695);
  ${p => p.css}
  `

const StyledModal = styled.div`
width: 400px;
background-color: #011c23;
  
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0 4rem;
  border-bottom: 1px solid #122c34;
  margin-bottom: 3rem;
  background-color: #01151c;
  & * p{
    color: #a9b3d1;
    font-weight: 600;
  }
`
const AlertIcon = styled.img`
  width: 25px;
`
const TitleWrapper = styled.div`
  display: flex;
`
const Title = styled.p`
  margin: 0 0 0 .5rem;
  padding: 1rem 0;
  color: #f9fdfd;
  text-transform: uppercase;
`
const CloseIcon = styled.img`
  padding: .25rem;
  cursor: pointer;
  
`
const Content = styled.div`
  text-align: left;
  padding: 0 2rem 3rem 4rem;
  color: #f9fdfd;
  width:65%;
`
const MetricWrapper = styled.div`
  margin-bottom: 2rem;
  & > div{
    padding: 0;
    gap: 0;
  &:nth-child(1) p {
    font-size: 2.2rem;
    &:nth-child(1) {
      font-size: 1.3rem;
    }
  }
}
& div > div { 
  flex-direction: column-reverse;
  justify-content: flex-end;  
}
  & img {
    width: 4rem;
  }
`
const Description = styled.p`
  margin: 0;
  margin-bottom: 2rem;
  padding-left: 1rem;
  font-size: .9rem;
`
const ButtonContainer = styled.div`
  display: flex;
  padding-left: 1rem;
  gap: 1rem;
`
const ButtonWrapper = styled.div`
  padding: .5rem;
  & button{
    height: auto;
    padding: .4rem 0;
    width: 170px;
    font-size: .8rem;
    text-transform: uppercase;
  }
`
