import { useEffect,useRef } from 'react'
import { red } from '../colors'
import { useFullScreenMode } from '../hooks/useFullScreenMode'

export const MissionPolyline = ({ path }:{path:{lat:number,lng:number}[]}) => {
  const ref = useRef<any>()
  const [fullScreenMode] = useFullScreenMode()

  useEffect(() => {
    ref.current = new google.maps.Polyline({ 
      path,
      strokeColor: red,
      strokeOpacity: 1,
      strokeWeight: 8,
    })
  },[])

  useEffect(() => {
    ref.current?.setPath(path)
  },[path])

  let timeout:any 

  useEffect(() => {

    const wait = () => {
      timeout = setTimeout(() => {
        if(!window.map) wait()
        else ref.current.setMap(window.map)    
      },0)
    }

    wait()

    return () => {
      ref.current?.setMap?.(null) 
      if(timeout) clearTimeout(timeout)
    }
  },[window.map,fullScreenMode])

  return null
}
 
