import { Menu } from './Menu'
import FleetStatusIconSVG from '../icons/FleetStatus.svg'
import AlertsIconSVG from '../icons/Alerts.svg'
import LogoutIconSVG from '../icons/Logout.svg'
import SettingsIconSVG from '../icons/Settings.svg'
import FleetControlIconSVG from '../icons/FleetControl.svg'
import { useAuth } from '../hooks/useAuth'
import { useLocation,useNavigate } from 'react-router-dom'
import { useRobotId } from '../hooks/useRobotId'
import { useControlAllocator } from '../hooks/useControlAllocator'


export function MainMenu():JSX.Element {
  const navigate = useNavigate()
  const [,setAuth] = useAuth()
  const [,setRobotId] = useRobotId()
  const [,setControlledId] = useControlAllocator()
  const location = useLocation()

  const items = [
    { 
      id: 0,
      label: `Fleet Control`,
      icon: FleetControlIconSVG,
      pathname: `/`,
      onClick: () => navigate(`/`),
    },
    {
      id: 1,
      label: `Fleet Status`,
      icon: FleetStatusIconSVG,
      pathname: `/fleetstatus`,
      onClick: () => navigate(`/fleetstatus`),
    },
    {
      id: 2,
      label: `Alerts`,
      icon: AlertsIconSVG, 
      pathname: `/alerts`,
      onClick: () => navigate(`/alerts`),
    },
    {
      id: 3,
      label: `Settings`,
      icon: SettingsIconSVG, 
      pathname: `/settings`,
      onClick: () => navigate(`/settings`),
    },
    {
      id: 4,
      label: `Logout`,
      icon: LogoutIconSVG, 
      onClick: () => {
        setAuth({ token: `` })
        localStorage.removeItem(`jwt`)
        setRobotId(``)
        setControlledId(``)
      },
    },
  ]

  const options = items.map(item => {
    return {
      ...item,
      active: location.pathname === item.pathname,
      onClick: (id:number) => {
        if(item.onClick) item.onClick()
      },

    }
  })

  return (
    <Menu 
      items={options}/>
  )
}
