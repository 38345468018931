/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TokenObtainPair } from '../models/TokenObtainPair';
import type { TokenObtainPairResponse } from '../models/TokenObtainPairResponse';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { TokenRefreshResponse } from '../models/TokenRefreshResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TokenService {

    /**
     * @param data
     * @returns TokenObtainPairResponse
     * @throws ApiError
     */
    public static tokenCreate(
        data: TokenObtainPair,
    ): CancelablePromise<TokenObtainPairResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/',
            body: data,
        });
    }

    /**
     * @param data
     * @returns TokenRefreshResponse
     * @throws ApiError
     */
    public static tokenRefreshCreate(
        data: TokenRefresh,
    ): CancelablePromise<TokenRefreshResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/refresh/',
            body: data,
        });
    }

}
