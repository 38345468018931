import { useChartTools } from '../hooks/useChartTools'
import { useLatestData } from '../hooks/useLatestData'
import { useRobotId } from '../hooks/useRobotId'
import { arrayFromWaypoints } from '../utils/arrayFromWaypoints'
import { Marker } from './Marker'
import { NavigationStackPolyline } from './NavigationStackPolyline'

export const NavigationStackLayer = () => {
  const [chartTools] = useChartTools()
  const [robotId] = useRobotId()
  const { data } = useLatestData(robotId)
  const pathTopic = data?.readings?.find(r => r.type === `path_topic`)
  const waypoints = pathTopic?.data?.data?.waypoints
  const path = arrayFromWaypoints(waypoints)

  return (
    <>
      <NavigationStackPolyline path={path}/>
      {chartTools[1].active && path.map((waypoint,index) => {
        return (
          <Marker 
            key={index}
            number={index}
            waypoint={waypoint}/>
        )
      })}
    </>
  )
}

