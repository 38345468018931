import { useMissionStatus } from '../hooks/useMissionStatus'
import { arrayFromWaypoints } from '../utils/arrayFromWaypoints'
import { Marker } from './Marker'
import { MissionPolyline } from './MissionPolyline'

export const MissionLayer = () => {
  const missionStatus = useMissionStatus()
  const path = arrayFromWaypoints(missionStatus?.data?.data?.waypoints || {})

  return (
    <>
      <MissionPolyline path={path}/>
      {path.map((waypoint,index) => {
        return (
          <Marker
            key={index}
            number={index}
            waypoint={waypoint}/>
        )
      })}
    </>
  )
}
