import { useRef,useState } from 'react'
import DraggableDialog from './DraggableDialog'
import styled from 'styled-components'
import MainIconSVG from '../icons/Layout.svg'
import IndoorIconSVG from '../icons/Indoor.svg'
import SaveIconSVG from '../icons/Save.svg'
import { useRobots } from '../hooks/useRobots'
import { useOccupancyGridRobotId } from '../hooks/useOccupancyGridId'
import { useRobotId } from '../hooks/useRobotId'
import { usePanToRobot } from '../hooks/usePanToRobot'
import { RobotListItem } from './RobotListItem'
import axios from 'axios'
import { useConfig } from '../hooks/useConfig'
import { useNotification } from '../hooks/useNotification'

export type LayoutOptionsProps = {
  css?: string 
}

export const ViewManager = ({ css }: LayoutOptionsProps):JSX.Element => {
  usePanToRobot()
  const moving = useRef(false)
  const mouseDown = useRef(false)
  const [menuActive,menuActiveSet] = useState(false)
  const { data: robots } = useRobots()
  const [roboSelectorActive,setRoboSelectorActive] = useState(false)
  const [robotId] = useRobotId()
  const [occupancyGridRobotId,setOccupancyGridRobotId] = useOccupancyGridRobotId()
  const [config] = useConfig()
  const [,setNotification] = useNotification()

  const menuItems = [
    {
      label: `Save View`,
      Icon: SaveIconSVG,
      handleClick: async() => {
        await axios({
          method: `post`,
          url: process.env.REACT_APP_API_CLIENT_BASE + `/view_config`,
          data: { config },
          headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
        })
        setNotification(`View has been saved`)
      },
    },
    {
      label: `Robot List`,
      Icon: IndoorIconSVG,
      handleClick: () => {
        setRoboSelectorActive(!roboSelectorActive)
      },
    },
  ]

  const handleConfirm = (e:any) => { 
    e.stopPropagation()
    menuActiveSet(false)
    setRoboSelectorActive(false)
    setOccupancyGridRobotId(occupancyGridRobotId ? `` : robotId)
  }

  const handleMenuClick = () => { 
    menuActiveSet(menuActive ? false : true)
    setRoboSelectorActive(false)
  }

  return (
    <DraggableDialog
      widget="view_manager_widget"
      style={{ background: `transparent` }}>
      <Container
        css={css}>
        <IconConatiner 
          onMouseMove={() => {
            if(!mouseDown.current) return
            setTimeout(() => moving.current = true,20)
          }}
          onMouseDown={() => {
            if(window.location.pathname === `/`) mouseDown.current = true 
          }}
          onMouseUp={() => {
            mouseDown.current = false
            setTimeout(() => moving.current = false,100)
          }}
          onClick={() => {
            if(!moving.current) handleMenuClick()
          }}>
          <MainIcon src={MainIconSVG}/>
        </IconConatiner>
        <Menu active={menuActive}>
          {menuItems.map((item,index) => {
            const { label,handleClick,Icon } = item
            return (
              <MenuItem
                key={index} 
                onMouseMove={() => {
                  if(!mouseDown.current) return
                  setTimeout(() => moving.current = true,20)
                }}
                onMouseDown={() => {
                  if(window.location.pathname === `/`) mouseDown.current = true 
                }}
                onMouseUp={() => {
                  mouseDown.current = false
                  setTimeout(() => moving.current = false,100)
                }}
                onClick={() => {
                  if(!moving.current) handleClick()
                }}>
                <MenuIcon src={Icon}/>
                <p>{label}</p>
              </MenuItem>
            )
          })}
        </Menu>
        <RoboSelector active={roboSelectorActive}>
          <RoboSelectorHeader>
            <p>Robot List</p>
          </RoboSelectorHeader>
          <RoboList>
            {robots?.results.map((robot,index) => {
              return (
                <RobotListItem key={index} robot={robot}/>
              )
            })}
          </RoboList>
          <ConfirmButton onClick={handleConfirm}>{`${occupancyGridRobotId ? `Disable` : `Enable`} Indoor Mode`}</ConfirmButton>
        </RoboSelector>
      </Container>
    </DraggableDialog>
  )
}

const Container = styled.div<{css?: string}>`
  color: white;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  background: rgb(3, 22, 28, 0.8);

  & p {
    margin: 0;
  }
  ${p => p.css}
  `

const IconConatiner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  /* border: 1px solid #b7c0d3; */
  cursor: pointer;
`
const MainIcon = styled.img`
  width: 24px;
  height: 24px;
`
const Menu = styled.div<{active: boolean}>`
  display: flex;
  overflow: auto;
  flex-direction: column;
  ${p => !p.active && `display:none;`}
`
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: .6rem;
  color: white;
  opacity: 70%;
  font-size: 1.1rem;
  cursor: pointer;
  padding: 1rem 1.5rem;
  &:hover {
    opacity: 100%;
  }
`
const MenuIcon = styled.img`
  width: 20px;
  height: 20px;
`
const RoboSelector = styled.div<{active: boolean}>`
  ${p => !p.active && `display:none;`}
`
const RoboSelectorHeader = styled.div`
  padding: 1rem;
  color: #f3f8f8;
  background-color: #00090f7b;
  font-weight: bold;
`
const RoboList = styled.div`
  max-height: 225px;
  /* background-color: #001e25; */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  overflow: hidden;
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`
const ConfirmButton = styled.button`
  background-color: #00090f7b;
  color: #5c787d;
  width: 100%;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
  &:hover{
      color: #90b1b7;
  }
`
