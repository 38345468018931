import { SelectInput } from '@wakeflow/ui'
import { useEffect,useState } from 'react'
import styled from 'styled-components'
import { useChartModal } from '../hooks/useChartModal'
import { useChartOptions } from '../hooks/useChartOptions'
import { useConfig } from '../hooks/useConfig'
import _ from 'lodash'

export const ChartOptionSelector = ({ line }:{line:number}) => {
  const [show] = useChartModal() as number[]
  const [config,setConfig] = useConfig()
  const chartOptions = useChartOptions()
  const [value,setValue] = useState(``)

  const options = chartOptions.map(o => {
    return {
      label: o.join(`.`),
      value: o.join(`---`),
    }
  })
  options.push({
    label: `none`,
    value: `none`,
  })

  useEffect(() => {
    const plots = config.fleet_status.plots[show < 3 ? `row_0` : `row_1`][show % 2 !== 0 ? `plot_0` : `plot_1`]
    const value = plots[line - 1]
    setValue(value)
  },[show,line,config])

  const handleChange = (change:string|number) => {
    const newValue = _.cloneDeep(config)
    newValue.fleet_status.plots[show < 3 ? `row_0` : `row_1`][show % 2 !== 0 ? `plot_0` : `plot_1`][line - 1] = String(change)
    setConfig(newValue)
  }

  return (
    <Container>
      <SelectInput
        label={`Line ${line}`}
        name={`Line ${line}`}
        onChange={handleChange}
        value={value}
        options={options}/>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`
