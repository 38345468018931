import { useRobotId } from './useRobotId'
import { RobotsService } from './../api/services/RobotsService'
import { useEffect } from 'react'
import { atom,useAtom } from 'jotai'
import { useLatestData } from './useLatestData'

const controllerAtom = atom(``)

export const useControlAllocator = () => {
  const [robotId] = useRobotId()
  const { data: latest } = useLatestData(robotId)
  const [,setControlledRobotId] = useAtom(controllerAtom)

  useEffect(() => {
    if(!robotId)return
    RobotsService.robotsRead(robotId)
      .then(robot => {
        if(robot.under_control && robot.id) 
          setControlledRobotId(robot.id)
      })
  },[robotId])

  useEffect(() => {
    if(latest?.robot?.id === robotId)
      if(!latest?.robot?.under_control)
        setControlledRobotId(``)
  },[latest])

  return useAtom(controllerAtom)
}
 
