import { Table } from './Table'
import { useRobots } from '../hooks/useRobots'
import { useRobotId } from '../hooks/useRobotId'
import styled from 'styled-components'

export function FleetStatusTable():JSX.Element {
  const { data: robots } = useRobots()
  const [,setRobotId] = useRobotId()
  const data = robots?.results || []

  const columns = [
    {
      label: `Name`,
      key: `name`,
      onClick: (row:any) => setRobotId(row.id),
    },
    {
      label: `ID`,
      key: `id`,
      onClick: (row:any) => setRobotId(row.id),
    },
    {
      label: `Last Active`,
      key: `last_active_ts`,
      format: (v:string) => new Date(v).toISOString(),
      onClick: (row:any) => setRobotId(row.id),
    },
    {
      label: `RTC Room`,
      key: `rtc_room_id`,
      onClick: (row:any) => setRobotId(row.id),
    },
    {
      label: `Status`,
      key: `status`,
      onClick: (row:any) => setRobotId(row.id),
    },
    {
      label: `Current Mission`,
      key: `current_mission_id`,
      onClick: (row:any) => setRobotId(row.id),
    },
    {
      label: `Busy`,
      key: `is_busy`,
      format: (v:any) => v ? `Busy` : `Idle`,
      onClick: (row:any) => setRobotId(row.id),
    },
  ]

  return (
    <Container>
      <Table columns={columns} data={data}/>
    </Container>
  )
}

const Container = styled.div`
  height:20vh;
  max-height:20vh;
  overflow:hidden;
`

