import { atom,useAtom } from 'jotai'

export const chartToolsAtom = atom([
  {
    id: 0,
    label: `Route Tools`,
    active: false,
  },
  {
    id: 1,
    label: `Route Feedback Overlay`,
    active: true,
  },
  {
    id: 2,
    label: `Odometry Overlay`,
  },
])

export const useChartTools = () => {
  return useAtom(chartToolsAtom)
}
