import { useControlAllocator } from '../hooks/useControlAllocator'
import { useRobot } from '../hooks/useRobot'
import { Button } from './Button'
import DraggableDialog from './DraggableDialog'
import { capitalCase } from 'change-case'
import axios from 'axios'
import { useRobotId } from '../hooks/useRobotId'
import { useChartTools } from '../hooks/useChartTools'
import styled from 'styled-components'

export function ControlAllocator():JSX.Element|null {
  const { data: robot } = useRobot()
  const [,setChartTools] = useChartTools()
  const [robotId] = useRobotId()
  const [controlledRobotId,setControlledRobotId] = useControlAllocator()

  const sendRequest = async(command:string,robotId:string) => {
    await axios({
      method: `post`,
      url: process.env.REACT_APP_API_CLIENT_BASE + `/control_module/${command}`,
      data: { robot_id: robotId },
      headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
    })
  }
  const handleControl = async() => {
    if(controlledRobotId) await sendRequest(`release_control`,controlledRobotId)
    await sendRequest(`take_control`,robotId)
    setControlledRobotId(robotId)
  }
  const handleRelease = async() => {
    await sendRequest(`release_control`,robotId)
    setControlledRobotId(``)
    setChartTools(s => ([{ ...s[0],active: false },...s.slice(1)]))
  }

  if(!robotId || (!robot?.under_control && robot?.controlled_by)) return null

  return (
    <DraggableDialog widget="control_allocator_widget">
      <Container>
        {controlledRobotId === robotId && <Button 
          onClick={handleRelease}
          border>Release {capitalCase(robot?.name || `Robot`)}</Button>}
        {controlledRobotId !== robotId && <Button 
          onClick={handleControl}
          border>Control {capitalCase(robot?.name || `Robot`)}</Button>}
      </Container>
    </DraggableDialog>
  )
}

const Container = styled.div`
  min-width:250px;
  padding:5px;
  box-sizing:border-box;
  display:flex;
  justify-content:center;
`
