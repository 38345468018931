//@ts-nocheck
import { useRoomId } from './useRoomId'
import { useEffect,useState } from 'react'
import { useJanus } from './useJanus'
import { randomString } from '../utils/randomString'

let textroom
let currentRoom
let myid = randomString()
let username = `robo-ui`
let participants = {}
let transactions = {}

export const useTextroom = () => {
  const [room] = useRoomId() 
  const [janusStatus,janus] = useJanus()
  const [status,setStatus] = useState(`idle`)
  useEffect(() => {
    if(currentRoom !== room) textroom = null
    if(janusStatus === `connected` && !textroom){
      currentRoom = room
      janus.attach({
        plugin: `janus.plugin.textroom`,
        opaqueId: `textroomtest-4617934631294`,
        success(pluginHandle) {
          textroom = pluginHandle
          textroom.send({ message: { request: `setup` } })
        },
        error(error) { },
        iceState(state) { },
        mediaState(medium,on) { },
        webrtcState(on) { },
        onmessage(msg,jsep) {
          // Janus.debug(` ::: Got a message :::`,msg)
          if(msg.error) console.log(msg.error)
          if(jsep) 
            textroom.createAnswer({
              jsep,
              tracks: [{ type: `data` }], // We only use datachannels 
              success(jsep) { textroom.send({ message: { request: `ack` },jsep }) },
              error(error) { console.log(`WebRTC error... ` + error.message) },
            })
        },
        ondataopen(data) {
          textroom.data({
            text: JSON.stringify({
              textroom: `join`,
              transaction: randomString(),
              room,
              //TODO: try removing username below
              username: myid,
              display: username,
            }),
            success: () => setStatus(`joined`), 
            error: console.log,
          })
        },
        ondata(data) {
          const json = JSON.parse(data)
          const transaction = json[`transaction`]
          if(transactions[transaction]) {
            // Someone was waiting for this
            transactions[transaction](json)
            delete transactions[transaction]
            return
          }
          const what = json[`textroom`]
          if(what === `message`) 
            try{
              const m = JSON.parse(json.text)
              if(m?.type === `ping` && json.from !== myid)
                textroom?.data?.({
                  text: JSON.stringify({
                    textroom: `message`,
                    transaction: randomString(12),
                    room: json.room,
                    text: json.text,
                  }),
                })
            }catch(err){
              console.log(`could not read received message`)
            }
            // const msg = escapeXmlTags(json[`text`])
            // const from = json[`from`]
            // const dateString = getDateString(json[`date`])
            // const whisper = json[`whisper`]
            // const sender = participants[from] ? participants[from] : escapeXmlTags(json[`display`])
            // log({ from,msg,dateString,whisper,sender })
          else if(what === `announcement`) 
            console.log(`Announcement:`,json)
          else if(what === `join`) {
            username = json[`username`]
            const display = json[`display`]
            participants[username] = escapeXmlTags(display ? display : username)
          } else if(what === `leave`) {
            username = json[`username`]
            delete participants[username]
          } else if(what === `kicked`) {
            username = json[`username`]
            delete participants[username]
            if(username === myid) console.log(`You have been kicked from the room`)
          } else if(what === `destroyed`) 
            if(json[`room`] !== room) console.log(`The room has been destroyed`)
        },
        oncleanup() {},
      })
    }
    return () => {
      textroom?.data?.({
        text: JSON.stringify({
          textroom: `leave`,
          room,
        }),
      })
      textroom?.detach?.()
      textroom?.hangup?.()
      textroom = null
      setStatus(`idle`)
    }
  },[janusStatus,room])

  return { status,textroom }
}

function escapeXmlTags(value) {
  if(value) {
    var escapedValue = value.replace(new RegExp(`<`,`g`),`&lt`)
    escapedValue = escapedValue.replace(new RegExp(`>`,`g`),`&gt`)
    return escapedValue
  }
}

