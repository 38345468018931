import { useRobotLatLng } from './useRobotLatLng'
import { useEffect } from 'react'
import { useRobotId } from './useRobotId'

export const usePanToRobot = () => {
  const [robotId] = useRobotId()
  const { lat: latitude,lng: longitude } = useRobotLatLng(robotId)
  useEffect(() => {
    if(latitude !== undefined && longitude !== undefined){
      const center = new google.maps.LatLng(latitude,longitude)
      window?.map?.panTo(center)
    }
  },[robotId])
}
