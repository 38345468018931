import styled from 'styled-components'

export const NotFound = () => {
  return (
    <>
      <StyledContainer>
        <StyledSecondTitle>
          <StyledSpan>4</StyledSpan>
          <StyledSpan>0</StyledSpan>
          <StyledSpan>4</StyledSpan>
        </StyledSecondTitle>
        <StyledTitle>Oops! Page not found</StyledTitle>
        <StyledAnchor href="/" >Go to Homepage</StyledAnchor>
      </StyledContainer>
    </>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

const StyledTitle = styled.h3`
  font-family: Cabin, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(38, 38, 38);
  letter-spacing: 3px;
 
`

const StyledSecondTitle = styled.h1`
  font-family: Montserrat, sans-serif;
  font-size: 400px;
  font-weight: 900;
  color: var(--theme-black);
  text-transform: uppercase;
  letter-spacing: -40px;
  margin: 0 auto;
 `

const StyledSpan = styled.span`
  text-shadow: -8px 0 0 var(--theme-white);
  font-weight: 900;
  font-family: titillium web,sans-serif;
`

const StyledAnchor = styled.a`
  font-family: titillium web,sans-serif;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  color: var(--theme-black);
  border-radius: 10px;
  text-decoration: none;
  border: none;
  background: var(--theme-green);
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 15px;
  -webkit-transition: .2s all;
  transition: .2s all;
  cursor:pointer;
`
