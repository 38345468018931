//@ts-nocheck
import { useVideoroomData } from './useVideoroomData'
import { useRoomId } from './useRoomId'
import { useEffect } from 'react'
import { useJanus } from './useJanus'
import { useVideoWarning } from './useVideoWarning'
import { opaqueId } from '../config'

let videoroom = null

export const useVideoroomPublisher = () => {
  const [,setVideoWarning] = useVideoWarning()
  const [,setVideoroomData] = useVideoroomData()
  const [room] = useRoomId() 
  const [janusStatus,janus] = useJanus()
  useEffect(() => {
    if(janusStatus === `connected` && !videoroom)
      janus.attach({
        plugin: `janus.plugin.videoroom`,
        opaqueId,
        success(pluginHandle) {
          // console.log(`callback called`,pluginHandle.id)
          if(videoroom) videoroom.detach()
          if(videoroom) videoroom.hangup()
          videoroom = pluginHandle
          if(videoroom === pluginHandle)
            videoroom.send({
              message: {
                request: `join`,
                room,
                ptype: `publisher`,
                display: `robo-ui-${new Date().toISOString()}`,
              }, 
            })
        },
        error(error) { console.error(`Error attaching plugin...`,error) },
        iceState(state) { },
        mediaState(medium,on,mid) { },
        webrtcState(on) { },
        slowLink(uplink,lost,mid) { },
        onmessage(msg,jsep) {
          // console.log({ msg })
          let event = msg[`videoroom`]
          if(!event) return
          if(event === `joined`) setVideoroomData(s => ({ ...s,myid: msg.id,private_id: msg.private_id }))
          if(event === `destroyed`) window.alert(`The room has been destroyed!`)
          if(msg.publishers) {
            const sources = getSourcesFromMsg(msg,setVideoWarning)
            const streams = getSubscriptionFromSources(sources)
            setVideoroomData(s => ({ ...s,streams }))
          }
          if(msg.leaving) setVideoroomData(s => ({ ...s,streams: s.streams.filter(s => s.feed !== msg.leaving) }))
          if(msg.unpublished) setVideoroomData(s => ({ ...s,streams: s.streams.filter(s => s.feed !== msg.unpublished) }))
          if(msg.error) console.log(msg.error)
        },
        onlocaltrack(track,on) {},
        onremotetrack(track,mid,on) {},
        oncleanup() {},
      })
    
    
    return () => {
      if(videoroom) videoroom.detach()
      if(videoroom) videoroom.hangup()
      setVideoroomData(null)
      videoroom = null
    }
  },[janusStatus,room,janus])
  return { status: `conntected`,videoroom }
}

const getSourcesFromMsg = (msg,setVideoWarning) => {
  let list = msg[`publishers`]
  setVideoWarning(list.length ? `` : `No video available`)
  return list
    .filter(item => !item.dummy)
    .map(item => {
      const { id,display,streams } = item
      streams.forEach(stream => {
        stream[`id`] = id
        stream[`display`] = display
      })
      return streams
    })
}

const getSubscriptionFromSources = sources => {
  let subscription = []
  sources.forEach(streams => {
    streams
      .filter(stream => !stream.disabled)
      .forEach(stream => {
        if(stream.type === `video` && Janus.webRTCAdapter.browserDetails.browser === `safari` && (stream.codec === `vp9` || (stream.codec === `vp8` && !Janus.safariVp8))) return console.warning(`Publisher is using ` + stream.codec.toUpperCase + `, but Safari doesn't support it: disabling video stream #` + stream.mindex) 
        subscription.push({
          feed: stream.id,	
          mid: stream.mid,	
        })
      })
  })
  return subscription
}
