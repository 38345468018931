//@ts-nocheck
import { useRef,useState,useEffect } from 'react'
import styled from 'styled-components'
import glow from '../icons/Glow.svg'
import knob from '../icons/Knob.svg'

const multiplier = 106

export const VisualFeedback = () => {
  const ref = useRef<HTMLImageElement>()
  const [angle,setAngle] = useState(0)
  const [mouseDown,setMouseDown] = useState(false)
  const [x,setX] = useState(0)
  const [y,setY] = useState(0)

  const handleMouseMove = (e:any) => {
    if(!mouseDown) return
    calculate(e)
  }

  const calculate = (e:any) => {
    const boundingRect = ref.current?.getBoundingClientRect()
    const inputX = (e.pageX - (boundingRect?.left || 0)) / (boundingRect?.width || 1) * 2 - 1
    const inputY = - ((e.pageY - (boundingRect?.top || 0)) / (boundingRect?.height || 1) * 2 - 1)
    const radians = Math.atan2(inputY,inputX)
    var pi = Math.PI
    const angle = radians * (180 / pi)
    setAngle(-angle)
    const amplitude = Math.sqrt(inputX * inputX + inputY * inputY)
    const x = inputX * 1 / (Math.max(1,amplitude))
    const y = inputY * 1 / (Math.max(1,amplitude))
    setX(x)
    setY(y)
  }

  const handleMouseUp = () => {
    setMouseDown(false)
    setX(0)
    setY(0)
  }

  useEffect(() => {
    window.addEventListener(`mouseup`,handleMouseUp)
    return () => window.removeEventListener(`mouseup`,handleMouseUp)
  })

  useEffect(() => {
    window.addEventListener(`mousemove`,handleMouseMove)
    return () => window.removeEventListener(`mousemove`,handleMouseMove)
  })
  
  return (
    <Container
      onMouseDown={e => {
        setMouseDown(true)
        calculate(e)
      }}
      onMouseUp={handleMouseUp}>
      <GlowImg 
        mouseDown={mouseDown}
        angle={angle}
        ref={ref}
        src={glow}/>
      <KnobImg
        x={x}
        y={y}
        src={knob}/>
    </Container>
  )
}
const Container = styled.div`
  grid-area: inner-div;
  width:220px;
  height:220px;
  position:relative;
`
const GlowImg = styled.img.attrs(p => ({ style: { transform: `rotate(${p.angle}deg)` } }))`
  opacity:${p => p.mouseDown ? 1 : 0};
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  border-radius:50%;
  pointer-events: initial;
  pointer-events:all;
  position:absolute;
  top:0;
  left:0;
`
const KnobImg = styled.img.attrs(p => ({ style: { transform: `translate(${Math.min(Math.max(p.x,-1),1) * multiplier}px,${-Math.min(Math.max(p.y,-1),1) * multiplier}px)` } }))`
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  border-radius:50%;
  pointer-events: initial;
  pointer-events:all;
  position:absolute;
  top:0;
  left:0;
`
