// @ts-nocheck
import { Form } from '@wakeflow/ui'
import { useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../hooks/useAuth'
import { Button } from './Button'
import { Card } from './Card'
import { OpenAPI,TokenObtainPair,TokenService } from '../api'
import { API_URL } from '../config'

export const Login = () => {
  const [,setAuth] = useAuth()
  const [values,setValues] = useState<TokenObtainPair>({ username: ``,password: `` })
  const [error,setError] = useState(``)

  const jsonSchema = {
    type: `object`,
    properties: {
      username: {
        type: `string`,
        // format: `email`,
      },
      password: {
        type: `string`,
        format: `password`,
        // pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        // errorMessage: `this is a custom error`,
      },
    },
  }

  function parseJwt(token) {
    var base64Url = token.split(`.`)[1]
    var base64 = base64Url.replace(/-/g,`+`).replace(/_/g,`/`)
    var jsonPayload = decodeURIComponent(window.atob(base64).split(``).map(function(c) {
      return `%` + (`00` + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(``))
    return JSON.parse(jsonPayload)
  }

  const handleClick = async() => {
    try{
      OpenAPI.BASE = API_URL
      const data = await TokenService.tokenCreate(values)
      const token = data.access
      localStorage.setItem(`jwt`,token || ``)
      OpenAPI.TOKEN = token || ``
      setAuth({ token,...parseJwt(token) })
    }catch(err){
      setError(err.message)
    }
  }

  // useEffect(() => {
  //   const handler = e => {
  //     if(e.key === `Enter`) handleClick()
  //   }
  //   document.addEventListener(`keydown`,handler)  
  //   return () => document.removeEventListener(`keydown`,handler)
  // },[])

  return (
    <Container>
      <Card
        css={`
          width:100vw;
          height:100vh;
          box-sizing:border-box;
          align-items:center;
          justify-content:center;
          .sc-ftvSup{
            border-radius:0;
            padding:4px;
            max-width:300px;
          }
        `}>
        <Spacer>
          <img 
            style={{ height: 200 }}
            src="/favicon.ico"/>
          <Form
            onEnterKeyDown={handleClick}
            css={`max-width:300px;`}
            values={values}
            setValues={setValues}
            jsonSchema={jsonSchema}/>      
          <Button onClick={handleClick} border>Login</Button>
          <Error>{error}</Error>
        </Spacer>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`
const Spacer = styled.div`
  gap:20px;
  display:flex;
  flex-direction:column;
  margin-bottom:20vh;
  width:100vw;
  height:100vh;
  align-items:center;
  justify-content:center;
`
const Error = styled.div`
  color:red;
`
