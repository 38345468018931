import { useEffect,useRef } from 'react'
import { useNotification } from '../hooks/useNotification'
import { NotificationBox } from './NotificationBox'
import styled from 'styled-components'

export function Notification():JSX.Element|null{
  const [notification,setNotification] = useNotification()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(notification){
      const timeout = setTimeout(() => {
        setNotification(``)
      },5000)
      return () => clearTimeout(timeout)
    }
  },[notification])

  if(!notification) return null
  return (
    <Container
      ref={ref}
      style={{ left: `calc( 50vw - ${(ref.current?.getBoundingClientRect?.()?.width || 0) / 2}px )` }}>
      <NotificationBox
        label={notification}/>
    </Container>
  )
}

const Container = styled.div`
  position:absolute;
  top:80vh;
`
