import './App.css'
import { AlertModal } from './components/AlertModal'
import { Router } from './components/Router'
import styled from 'styled-components'

function App() {

  return (
    <Container className="App">
      <AlertModal/>
      <Router/>
    </Container>
  )
}

export default App

const Container = styled.div`
  width:100vw;
  min-height:100vh;
`
