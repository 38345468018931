import styled from 'styled-components'

export const MiniMap = () => {
  return (
    <Container 
      onMouseDown={(e:any) => e.stopPropagation()}
      onMouseUp={(e:any) => e.stopPropagation()}
      id="mini-map">
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  height:300px;
`
