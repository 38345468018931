import { ReactNode } from 'react'
import styled from 'styled-components'
import { MainMenu } from './MainMenu'
export interface MenuViewProps {
  icon: string
  css?: string
  children:ReactNode
}
export function MenuView({ css = ``,icon,children }: MenuViewProps):JSX.Element {
  return (
    <Container css={css}>
      <MenuContainer>
        <Iconwrapper>
          <BackgroundIcon src={icon}/>
        </Iconwrapper>
        <MainMenu/>
      </MenuContainer>
      <ContentContainer>
        {children}
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div<{css:string}>`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  box-sizing: border-box;
  display: flex;
  * {
  box-sizing: border-box;
  }
  height:100vh;
  width:100vw;

  ${p => p.css}
`

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #03181d;
  overflow: hidden;
  min-width:250px;
`
const ContentContainer = styled.div`
  flex-grow: 1;
  background-color: #011c23;
`
const Iconwrapper = styled.div`
  position: absolute;
  top:40%;
  transform: translate(-40%,-50%);
  width: 130%;
`
const BackgroundIcon = styled.img`
  width: 100%;
`
