import { useConfig } from './useConfig'
import { useEffect,useState } from 'react'
import axios from 'axios'
import _ from 'lodash'

export const useLoadViewConfig = () => {
  const [config,setConfig] = useConfig()
  const [ready,setReady] = useState(false)
  useEffect(() => {
    axios({
      method: `get`,
      url: process.env.REACT_APP_API_CLIENT_BASE + `/view_config`,
      headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
    }).then(res => {
      setConfig(_.merge(config,res.data.config))
      setReady(true)
    }).catch(err => {
      console.log(err)
      setReady(true)
    })
  },[])
  return ready
}
