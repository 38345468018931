import { useLatestData } from './useLatestData'
import { useRobotId } from './useRobotId'


export const useChartOptions = () => {
  const [robotId] = useRobotId()
  const { data } = useLatestData(robotId)
  const output:string[][] = []

  const recursive = (path:string[],obj:{[key:string]:any}) => {
    Object.keys(obj).forEach(key => {
      if(typeof obj[key] === `number`) output.push([...path,key])
      if(Array.isArray(obj[key])) return
      if(typeof obj[key] === `object`) recursive([...path,key],obj[key])
    })
  }

  data?.readings?.forEach(reading => {
    recursive([reading.type],reading.data)
  })

  return output
}
