import { useRobotLatLng } from './useRobotLatLng'
import { useEffect } from 'react'
import { useRobotId } from './useRobotId'
import { atom,useAtom } from 'jotai'

const odometryOverlayAtom = atom<{lat:number,lng:number}[]>([
  // {
  //   "lat": 25.121383636862703,
  //   "lng": 55.45577360422125,
  // },
  // {
  //   "lat": 25.12290725610761,
  //   "lng": 55.45784468524655,
  // },
  // {
  //   "lat": 25.12182901169175,
  //   "lng": 55.460951344095115,
  // },
  // {
  //   "lat": 25.123024455963066,
  //   "lng": 55.45981224836017,
  // },
])

export const useOdometryOverlay = () => {
  const [robotId] = useRobotId()
  const [array,setArray] = useAtom(odometryOverlayAtom)
  const { lat,lng } = useRobotLatLng(robotId)

  useEffect(() => {
    if(lat || lng)
      setArray(s => ([...s.slice(Math.max(0,s.length - 29)),{ lat,lng }]))
  },[lat,lng])

  useEffect(() => {
    setArray([])
  },[robotId])

  return array
}
