import { useState,useEffect } from 'react'
import { useOccupancyGridRobotId } from './useOccupancyGridId'
import { useLatestData } from './useLatestData'
import axios from 'axios'
import { useNotification } from './useNotification'

export const useOccupancyGrid = () => {
  const [,setNotification] = useNotification()
  const [output,setOutput] = useState<any>({})
  const [occupancyGridRobotId,setOccupancyGridRobotId] = useOccupancyGridRobotId()
  const { data } = useLatestData(occupancyGridRobotId)
  const occupancyGridId = data?.readings?.find(d => d.type === `occupancy_grid`)?.data?.id

  useEffect(() => {
    if(!occupancyGridId) {
      setNotification(`No Indoor Map available`)
      return setOccupancyGridRobotId(``)
    }
    setNotification(`Downloading Indoor Map...`)
    axios({
      method: `get`,
      url: process.env.REACT_APP_API_CLIENT_BASE + `/occupancy_grid/${occupancyGridId}`,
      headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
    })
      .then(res => {
        setNotification(`Indoor Map Downloaded`)
        if(res?.data?.grid_data) {
          setOutput(res.data)
          setNotification(`Indoor Map Downloaded`)
        }else{
          setNotification(`No Indoor Map available`)
          setOccupancyGridRobotId(``)
        }
      })
      .catch(err => {
        console.log(err)
        setNotification(`No Indoor Map available`)
      })
  },[occupancyGridId])
  return output
}
