import { useControlAllocator } from '../hooks/useControlAllocator'
import { useRobotId } from '../hooks/useRobotId'
import { Button } from './Button'
import DraggableDialog from './DraggableDialog'
import axios from 'axios'
import { useLatestData } from '../hooks/useLatestData'
import styled from 'styled-components'

export function EmergencyStop():JSX.Element|null {
  const [controlledRobotId] = useControlAllocator()
  const [robotId] = useRobotId()
  const { data } = useLatestData(robotId) as any

  const missionId = data?.robot?.current_mission_id

  const handleClick = async() => {
    if(missionId)
      await axios({
        method: `post`,
        url: process.env.REACT_APP_API_CLIENT_BASE + `/mission/clear`,
        data: { mission_id: missionId },
        headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
      })
  }
   
  if(!robotId || controlledRobotId !== robotId) return null
  return (
    <DraggableDialog widget="mission_stop_widget">
      <Container>
        <Button 
          css={`background-color: transparent;`}
          onClick={handleClick}
          border
          alert>Cancel Mission</Button>
      </Container>
    </DraggableDialog>
  )
}

const Container = styled.div`
  min-width: 250px;
  padding: 5px;
  box-sizing: border-box;
  display:flex;
  justify-content:center;
`
