import { useEffect,useRef } from 'react'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { useRobotId } from '../hooks/useRobotId'
import { useRobotLatLng } from '../hooks/useRobotLatLng'
import { useRobotRotation } from '../hooks/useRobotRotation'
import { getRobotColor } from '../utils/getRobotColor'

export const DirectionMarker = ({ robot }:{robot:any}) => {
  const [,setRobotId] = useRobotId()
  const [fullScreenMode] = useFullScreenMode()
  const rotation = useRobotRotation(robot.id)
  const { lat,lng } = useRobotLatLng(robot.id)
  const color = getRobotColor(robot)

  const ref = useRef(new google.maps.Marker())
  ref.current.addListener(`click`,() => setRobotId(robot.id))

  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40" transform="rotate(${Number(rotation + 90).toFixed()})" style="transform-origin:center;">
    <g id="Robot_Faulty" data-name="Robot Faulty">
      <path id="Rectangle_155" data-name="Rectangle 155" d="M6.247,4.318A2.939,2.939,0,0,0,3.313,7.256v3.489a2.939,2.939,0,0,0,2.933,2.937H20.753a2.939,2.939,0,0,0,2.933-2.937V7.256a2.939,2.939,0,0,0-2.933-2.937H6.247M6.247,1H20.753A6.251,6.251,0,0,1,27,7.256v3.489A6.251,6.251,0,0,1,20.753,17H6.247A6.251,6.251,0,0,1,0,10.744V7.256A6.251,6.251,0,0,1,6.247,1Z" transform="translate(10 11) rotate(0)" fill="${color}"/>
      <path id="keyboard_double_arrow_left_FILL0_wght600_GRAD-25_opsz48" d="M22.439,23.711l-6.131-6.155L22.439,11.4l1.324,1.324v9.638Z" transform="translate(-15 2.5) rotate(0)" fill="${color}"/>
    </g>
  </svg>
  `

  useEffect(() => {
    ref.current.setIcon({
      url: `data:image/svg+xml;charset=UTF-8;base64,` + btoa(svg),
      anchor: new google.maps.Point(20,20),
    })
  })

  useEffect(() => {
    if(typeof lat === `number` && typeof lng === `number`)ref.current.setPosition({ lat,lng })
  },[lat,lng])

  useEffect(() => {
    const wait = () => {
      setTimeout(() => {
        if(!window.map) wait()
        else ref.current.setMap(window.map)    
      },0)
    }
    wait()
    return () => ref.current.setMap(null) 
  },[window.map,fullScreenMode])

  return null
}
