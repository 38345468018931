import styled from 'styled-components'
import { DirectionMarker } from './DirectionMarker'
import { HaloMarker } from './HaloMarker'

export const RobotMarker = ({ robot }:{robot:any}) => {
  return (
    <Container>
      <HaloMarker robot={robot}/>
      <DirectionMarker robot={robot}/>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`
