import styled from 'styled-components'
import { useAlerts } from '../hooks/useAlerts'
import AlertIconSVG from '../icons/Alerts.svg'
import { Table } from './Table'
import { MenuView } from './MenuView'
// import { Button } from './Button'
import dayjs from 'dayjs'

export const Alerts = () => {
  const [alerts] = useAlerts() as [string[]]
  const data = alerts.map((a:any) => {
    return {
      ...a?.data,
      createdAt: a.created, 
      day: new Date(a.created),
      time: dayjs(a.created).format(`HH:mm`),
      date: dayjs(a.created).format(`DD MMM YYYY`),
    }
  })

  // const handleClick = () => { 
    
  // }
  return (
    <Container>
      <MenuView
        icon={AlertIconSVG}>
        <Children>
          <Table 
            columns={[
              { label: `Level`,key: `level` },
              { label: `Date`,key: `date` },
              { label: `Time`,key: `time` },
              { label: `Message`,key: `message` },
            ]}
            data={data}/>
          {/* <ButtonContainer>
            <Button onClick={handleClick}border alert>Dismiss alert</Button>
          </ButtonContainer> */}
        </Children>
      </MenuView>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  table {
    margin-bottom: 2rem;
  }
`
const Children = styled.div`
display: flex;
flex-direction: column;

`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  button {
    text-transform: uppercase;
  }
`
