import styled from 'styled-components'
import SettingsIconSVG from '../icons/Settings.svg'
import { MenuView } from './MenuView'
import { Button } from './Button'
import { NumberInput,Toggle } from '@wakeflow/ui'
import { useState } from 'react'
import { useUpdateRate } from '../hooks/useUpdateRate'
import { useConfig } from '../hooks/useConfig'
import _ from 'lodash'
import axios from 'axios'

export const Settings = () => {
  const [updateRate,setUpdateRate] = useUpdateRate()
  const [value,setValue] = useState(updateRate / 1000)
  const [config,setConfig] = useConfig()
  const [confirmation,setConfirmation] = useState(``)

  const handleClick = async() => { 
    if(value >= 1 && value <= 10){
      setUpdateRate(value * 1000)
      await axios({
        method: `post`,
        url: process.env.REACT_APP_API_CLIENT_BASE + `/view_config`,
        data: { config },
        headers: { authorization: `Bearer ${localStorage.getItem(`jwt`)}` },
      })
      setConfirmation(`Your updates have been saved`)
      setTimeout(() => { setConfirmation(``) },2000)
    }
  }

  const handleToggle = () => {
    const newValue = _.cloneDeep(config)
    if(!newValue.settings) newValue.settings = { followRobot: false }
    newValue.settings.followRobot = !config?.settings?.followRobot
    setConfig(newValue)
  }

  return (
    <Container>
      <MenuView
        icon={SettingsIconSVG}>
        <Children>
          <ButtonContainer>
            <FormContainer>
              <NumberInput
                name="updateRate"
                label= {`Update Rate in Seconds`}
                value={value}
                onChange={setValue}
                jsonSchema={{
                  type: `number`,
                  minimum: 1,
                  maximum: 10,
                }}/>      
              <Toggle
                name="updateRate"
                label= {`Follow Robot?`}
                value={!!config?.settings?.followRobot}
                onChange={handleToggle}
                jsonSchema={{ type: `boolean` }}/>      
              <Confirmation>{confirmation}</Confirmation>
              <Button onClick={handleClick}>Update</Button>
            </FormContainer>
          </ButtonContainer>
        </Children>
      </MenuView>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  table {
    margin-bottom: 2rem;
  }
`
const Children = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items:center;
  padding-right: 2rem;
  flex-direction:column;
  button {
    text-transform: uppercase;
  }
`
const FormContainer = styled.div`
  margin: 10vh;
  max-width:300px;
  background-color:#153a47;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content: center;
  flex-direction:column;
  padding:20px;
  border-radius:4px;
  gap:10px;
`
const Confirmation = styled.div`
  color:white;
  min-height:20px;
`
