import { useEffect } from 'react'
import { useJotaiAtom } from './useJotaiAtom'

const alreadyRefetched:{[key:string]:boolean} = {}
const alreadyCalled:{[key:string]:boolean} = {}
const intervals:{[key:string]:ReturnType<typeof setInterval>} = {}

export function useAPI<T>(atom:string,func:()=>Promise<T|null>,interval = 0,ready:boolean = true):{
  status:string,
  data:T|null,
  refetch:()=>void
}{
  const [values,setValues]:[{status:string,data:null|T},(s:any)=>any] = useJotaiAtom(atom)

  useEffect(() => {
    if(ready && !alreadyCalled[atom]){
      alreadyCalled[atom] = true
      setValues((s:any) => ({ ...s,status: `loading` }))
      func().then((res:T|null) => {
        setValues({ status: `succeeded`,data: res })
        if(intervals[atom]) clearInterval(intervals[atom])
        if(interval) intervals[atom] = setInterval(() => refetch(),interval) 
      }).catch(err => {
        console.log(err)
        setValues({ status: `failed`,data: null })

        // if(intervals[atom]) clearInterval(intervals[atom])
        if(intervals[atom]) clearInterval(intervals[atom])
        if(interval) intervals[atom] = setInterval(() => refetch(),interval) 

        alreadyCalled[atom] = false
      })
    }
  })

  const refetch = () => {
    if(ready && !alreadyRefetched[atom]){
      alreadyRefetched[atom] = true
      setValues((s:any) => ({ ...s,status: `loading` }))
      func().then((res:T|null) => {
        setValues({ status: `succeeded`,data: res })
      }).catch(error => {
        console.log(error)
        setValues({ status: `failed`,data: null })
        if(intervals[atom]) clearInterval(intervals[atom])
        if(interval) intervals[atom] = setInterval(() => refetch(),interval) 
      })
      setTimeout(() => alreadyRefetched[atom] = false,100)
    }
  }

  useEffect(() => {
    return () => {
      alreadyCalled[atom] = false
      alreadyRefetched[atom] = false
      setValues((s:any) => ({ ...s,status: `idle` }))
      if(intervals[atom]) clearInterval(intervals[atom])
    }
  },[atom])


  return { ...values,refetch } 
}

