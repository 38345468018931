//@ts-nocheck
import { useEffect,useState } from 'react'
import { useLatestData } from '../hooks/useLatestData'
import { useRobotId } from '../hooks/useRobotId'
import { getDataPoint } from '../utils/getDataPoint'
import { Chart } from './Chart'

export const ChartProvider = ({ number,options }:{number:number,options:string[]}) => {
  const [robotId] = useRobotId()
  const { data: latest } = useLatestData(robotId)
  const [list1,setList1] = useState([])
  const [list2,setList2] = useState([])

  useEffect(() => {
    if(options[0]){
      const newValue1 = getDataPoint(latest,options[0].split(`---`))
      if(newValue1 !== null) setList1(s => ([...s.slice(Math.max(0,s.length - 59),s.length),{ value: newValue1,timestamp: new Date() }]))
    }
    if(options[1]){
      const newValue2 = getDataPoint(latest,options[1].split(`---`))
      if(newValue2 !== null) setList2(s => ([...s.slice(Math.max(0,s.length - 59),s.length),{ value: newValue2,timestamp: new Date() }]))
    }
  },[latest])

  useEffect(() => {
    setList1([])
    setList2([])
  },[robotId,options])

  const data = {}
  if(options[0]) data[options[0].replaceAll(`---`,`.`)] = list1
  if(options[1]) data[options[1].replaceAll(`---`,`.`)] = list2

  return (
    <Chart 
      number={number}
      data={data}
      label={`Chart ${number}`}/>
  )
}
