//@ts-nocheck
import { useEffect,useRef } from 'react'
import styled from 'styled-components'
import { useCameraStream } from '../hooks/useCameraStream'
import { useFullScreenMode } from '../hooks/useFullScreenMode'

export const Video = () => {

  const ref = useRef<HTMLVideoElement>()
  const [stream] = useCameraStream()
  const [fullScreenMode] = useFullScreenMode()

  useEffect(() => {
    if(stream) Janus?.attachMediaStream?.(ref.current,stream)
  },[stream])

  useEffect(() => {
    const video = ref.current
    const isVideoPlaying = video => video && !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
    setInterval(() => {
      if(video && !isVideoPlaying(video)) video.play()  
    },1000)
  },[])


  return (
    <Container fullScreenMode={fullScreenMode}>
      <StyledVideo            
        ref={ref}
        hidden={!stream}
        className="rounded centered"
        id="remotevideo"
        width="100%"
        autoPlay
        playsInline/>
      {!stream && <Warning>No Video Source Available</Warning>}
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  height:${p => p.fullScreenMode ? `100vh` : `300px`};
  object-fit:contain;
  background-color:rgba(7, 34, 42, 0.9);
`
const StyledVideo = styled.video`
  background-color: rgba(3, 23, 29, 0.95);
  object-fit:contain;
  width:100%;
  height:100%;
`
const Warning = styled.div`
  color:red !important;
  font-size:1.5rem;
`
