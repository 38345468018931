import { useState } from 'react'
import styled from 'styled-components'
import CameraIconSVG from '../icons/Camera.svg'
import DraggableDialog from './DraggableDialog'
import { Button } from './Button'
import { Card } from './Card'
import { CameraSelector } from './CameraSelector'
import { useRobotId } from '../hooks/useRobotId'
import { useShowCamera } from '../hooks/useShowCamera'

export function ShowHideCamera({ css }: {css?:string}):JSX.Element|null {
  const [show,setShow] = useShowCamera()
  const [robotId] = useRobotId()

  if(!robotId) return null
  return (
    <DraggableDialog widget="camera_widget">
      <Container css={css}>
        <Card css={`padding:10px;`}>
          <Button
            // css={`width:100%;`}
            icon={CameraIconSVG}
            onClick={() => setShow(!show)}
            border>{`${show ? `Hide` : `Show`} Camera`}</Button>
        </Card>
        {show && <CameraSelector/> }
      </Container>
    </DraggableDialog>
  )
}

const Container = styled.div<{css?:string}>`
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
    color: #b7c0d3;
  }
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: .9rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  /* background-image: url('https://gcdnb.pbrd.co/images/ScU6n1mih6Qd.png?o=1'); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: contain;
  
  ${p => p.css}
`
