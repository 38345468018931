import { useEffect,useRef } from 'react'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import pin from '../icons/Pin.svg'

declare global {
  interface Window {
    map: any;
  }
}

export const Marker = ({ waypoint,number }:{waypoint:any,number:number}) => {
  const [fullScreenMode] = useFullScreenMode()
  const { lat,lng } = waypoint
  const ref = useRef(new google.maps.Marker())
  ref.current.setLabel(String(number + 1))
  ref.current.setIcon({
    url: pin,
    anchor: new google.maps.Point(15,35),
  })

  useEffect(() => {
    if(lat && lng)ref.current.setPosition({ lat,lng })
  },[lat,lng])

  useEffect(() => {
    const wait = () => {
      setTimeout(() => {
        if(!window.map) wait()
        else ref.current.setMap(window.map)    
      },0)
    }
    wait()
    return () => ref.current?.setMap?.(null) 
  },[window.map,fullScreenMode])

  return null
}
