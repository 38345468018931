import { useRobot } from './useRobot'

export const useRoomId = () => {
  const { data: robot } = useRobot()
  let id = robot?.rtc_room_id || 1234
  // console.log({ id })
  // if(id === 0 || id === 1)id = [1234,1235][id] 
  //TODO: make sure below is not overridden
  return [id]
  // return [1234]
}
