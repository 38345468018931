import { useUpdateRate } from './useUpdateRate'
import { useLatestData } from './useLatestData'
import { useAPI } from './useAPI'
import { MissionService } from '../api'
import { useRobotId } from './useRobotId'

export const useMissionStatus = () => {
  const [robotId] = useRobotId()
  const [updateRate] = useUpdateRate()
  const { data } = useLatestData(robotId)
  const missionId = data?.robot?.current_mission_id || `` 
  return useAPI<any>(
    `missionStatus - ${missionId}`,
    () => MissionService.missionStatus(missionId),
    updateRate,
    !!missionId, 
  )
}
