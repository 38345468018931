import { atom,useAtom } from 'jotai'

const configAtom = atom({
  fleet_control_page_view: {
    widgets_size_and_pos: {
      metrics_widget: {
        x: `1vh`,
        y: `1vh`,
      },
      view_manager_widget: {
        x: `calc(2vw + 250px)`,
        y: `calc(1vh)`,
      },
      camera_widget: {
        x: `calc(99vw - 250px - 400px)`,
        y: `1vh`,
      },
      menu_widget: {
        x: `1vh`,
        y: `calc(99vh - 264px)`,
      },
      joystick_widget: {
        x: `calc(99vw - 130px)`,
        y: `calc(99vh - 155px)`,
      },
      chart_tools_widget: {
        x: `calc(99vw - 250px)`,
        y: `calc(99vh - 500px - 1vh)`,
      },
      route_tools_widget: {
        x: `calc(98vh - 466px)`,
        y: `calc(98vw - 250px - 250px)`,
      },
      mission_stop_widget: {
        x: `calc(99vw - 250px)`,
        y: `calc(99vh - 620px - 1vh)`,
      },
      control_allocator_widget: {
        x: `calc(99vw - 250px)`,
        y: `calc(99vh - 560px - 1vh)`,
      },
      notification_widget: {
        x: `calc(50vw - 197px)`,
        y: `90vh`,
      },
    },
  },
  fleet_status: {
    plots: {
      number_of_rows: 2,
      relative_row_sizes: [
        0.5,0.5,
      ],
      row_0: {
        number_of_columns: 2,
        relative_column_sizes: [0.5,0.5],
        plot_0: [
          `battery_level---percentage`,
        ],
        plot_1: [
          `connectivity_diagnostics---latency`,
        ],
      },
      row_1: {
        number_of_columns: 2,
        relative_column_sizes: [0.5,0.5],
        plot_0: [
          `gps_location---latitude`,
          `gps_location---longitude`,
        ],
        plot_1: [
          `vehicle_odometry---twist---twist---linear---z`,
        ],
      },
    },
  },
  settings: { followRobot: false },
})

export const useConfig = () => {
  return useAtom(configAtom)
}
