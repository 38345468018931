//@ts-nocheck
import React,{ useEffect,useRef,useState } from 'react'
import Draggable from 'react-draggable'
import styled from 'styled-components'
import { useConfig } from '../hooks/useConfig'

const DraggableDialog: React.FC<{
  children: JSX.Element;
  widget?: string,
  style?: React.CSSProperties;
}> = ({ widget,children,style }) => {
  const [config,setConfig] = useConfig()
  const [localStyles,setLocalStyles] = useState(style)
  const ref = useRef()

  useEffect(() => {
    if(widget){
      const { y: top,x: left } = config.fleet_control_page_view.widgets_size_and_pos[widget]
      if(typeof top === `string` && typeof left === `string`) 
        setLocalStyles({ top,left })
    }
  },[])

  const handleDrag = () => {
    var clientRect = ref.current.getBoundingClientRect()
    if(!widget) return
    if(!clientRect) return
    const xy = { x: Math.round(clientRect.left),y: Math.round(clientRect.top) }
    const newConfig = _.cloneDeep(config)
    newConfig.fleet_control_page_view.widgets_size_and_pos[widget].x = `${xy.x}px`
    newConfig.fleet_control_page_view.widgets_size_and_pos[widget].y = `${xy.y}px`
    setConfig(newConfig)
  }

  const finalStyle = { 
    ...localStyles,
    top: `min( ${localStyles?.top || 0} , calc( 99vh - ${ref.current?.getBoundingClientRect?.()?.height}px ) )`,
    left: `min( ${localStyles?.left || 0} , calc( 99vw - ${ref.current?.getBoundingClientRect?.()?.width}px ) )`,
  }
  if(!widget)console.log(finalStyle)

  return (
    <Draggable
      bounds="parent"
      onDrag={handleDrag}>
      <Container 
        className="asdf"
        ref={ref}
        style={finalStyle}>
        {children}
      </Container>
    </Draggable>
  )
}

export default DraggableDialog

const Container = styled.div`
  position: absolute;
  z-index: 1;
  cursor: move;
  background: rgb(3, 22, 28, 0.8);
  box-sizing:border-box;
  color: #fff;
  display:flex;
  justify-content:center;
  align-items:center;
`
