import { Modal } from '@wakeflow/ui'
import styled from 'styled-components'
import { useChartModal } from '../hooks/useChartModal'
import { ChartOptionSelector } from './ChartOptionSelector'

export const ChartsModal = () => {
  const [show,setShow] = useChartModal()
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Close" transform="translate(-1523 -413)">
    <rect id="Rectangle_242" data-name="Rectangle 242" width="24" height="24" transform="translate(1523 413)" fill="black" opacity="0"/>
    <path id="add_circle_FILL0_wght600_GRAD-25_opsz48" d="M16.78,26.3h2.655V19.456h6.886V16.8H19.435V9.625H16.78V16.8H9.645v2.655H16.78ZM17.983,17.963Z" transform="translate(1534.777 399.374) rotate(45)" fill="black"/>
  </g>
</svg>
`
  return (
    <Modal show={!!show} setShow={setShow}>
      <Container>
        <CloseIcon 
          onClick={() => setShow(false)}
          src={`data:image/svg+xml;charset=UTF-8;base64,` + btoa(svg)} />
        <h1>{`Chart ${show} Data`}</h1>
        <ChartOptionSelector line={1}/>
        <ChartOptionSelector line={2}/>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;
`
const CloseIcon = styled.img`
  cursor: pointer;
  width: 25px;
  align-self:flex-end;
`
