import { getDataPoint } from './../utils/getDataPoint'
import { useLatestData } from './useLatestData'
import { rotationFromQuaternion } from '../utils/rotationFromQuaternion'

export const useRobotRotation = (robotId:string) => {
  const { data } = useLatestData(robotId)
  const quaternion = getDataPoint(data,[`vehicle_odometry`,`pose`,`pose`,`orientation`])
  const rotation = rotationFromQuaternion(quaternion)
  return Number(rotation)
}
