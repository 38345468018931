/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Robot } from '../models/Robot';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RobotsService {

    /**
     * @param page A page number within the paginated result set.
     * @returns any
     * @throws ApiError
     */
    public static robotsList(
        page?: number,
    ): CancelablePromise<{
        count: number;
        next?: string | null;
        previous?: string | null;
        results: Array<Robot>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/robots/',
            query: {
                'page': page,
            },
        });
    }

    /**
     * @param data
     * @returns Robot
     * @throws ApiError
     */
    public static robotsCreate(
        data: Robot,
    ): CancelablePromise<Robot> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/robots/',
            body: data,
        });
    }

    /**
     * @param id A UUID string identifying this robot.
     * @returns Robot
     * @throws ApiError
     */
    public static robotsRead(
        id: string,
    ): CancelablePromise<Robot> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/robots/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A UUID string identifying this robot.
     * @param data
     * @returns Robot
     * @throws ApiError
     */
    public static robotsUpdate(
        id: string,
        data: Robot,
    ): CancelablePromise<Robot> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/robots/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A UUID string identifying this robot.
     * @param data
     * @returns Robot
     * @throws ApiError
     */
    public static robotsPartialUpdate(
        id: string,
        data: Robot,
    ): CancelablePromise<Robot> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/robots/{id}/',
            path: {
                'id': id,
            },
            body: data,
        });
    }

    /**
     * @param id A UUID string identifying this robot.
     * @returns void
     * @throws ApiError
     */
    public static robotsDelete(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/robots/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
