import styled from 'styled-components'
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { ProtectedRoute } from './ProtectedRoute'
import { NotFound } from './NotFound'
import { MapView } from './MapView'
import { Login } from './Login'
import { Alerts } from './Alerts'
import { FleetStatus } from './FleetStatus'
import { Settings } from './Settings'

export const Router = () => {
  return (
    <Container>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route
            path="/map"
            element={
              <PrivateRoute>
                <MapView />
              </PrivateRoute>
            } />
          <Route
            path="/login"
            element={
              <ProtectedRoute>
                <Login />
              </ProtectedRoute>
            } />
          <Route
            path="/alerts"
            element={
              <PrivateRoute>
                <Alerts/>
              </PrivateRoute>
            } />
          <Route
            path="/fleetstatus"
            element={
              <PrivateRoute>
                <FleetStatus/>
              </PrivateRoute>
            } />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings/>
              </PrivateRoute>
            } />
          {/* <Route
            path="/signup"
            element={
              <ProtectedRoute>
                <SignUp />
              </ProtectedRoute>
            } /> */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MapView />
              </PrivateRoute>
            } />
        </Routes>
      </BrowserRouter>
      
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  height:100vh;
`
