import DraggableDialog from './DraggableDialog'
import { Menu } from './Menu'
import RectFilled from '../icons/rectangle_filled.svg'
import RectEmpty from '../icons/rectangle_empty.svg'
import { useChartTools } from '../hooks/useChartTools'
import { useControlAllocator } from '../hooks/useControlAllocator'
import { useNotification } from '../hooks/useNotification'
import { useRobotId } from '../hooks/useRobotId'
import { useEffect } from 'react'


export function ChartTools():JSX.Element|null{
  const [items,setItems] = useChartTools()
  const [controlledRobotId] = useControlAllocator()
  const [,setNotification] = useNotification()
  const [robotId] = useRobotId()

  const options = items.map(item => {
    return {
      ...item,
      icon: item.active ? RectFilled : RectEmpty,
      onClick: (id:number) => {
        setItems(items.map(item => {
          return {
            ...item,
            active: item.id === id ? !item.active : item.active,
          }
        }))
      },
    }
  })

  options[0].onClick = () => {
    if(!items[0].active && !controlledRobotId) return setNotification(`You need to control a robot to use Route Tools`)
    setItems(items.map(item => {
      return {
        ...item,
        active: item.id === items[0].id ? !item.active : item.active,
      }
    }))
  }

  useEffect(() => {
    return () => {
      setItems(items.map((item,index) => {
        if(index !== 0) return item 
        return {
          ...item,
          active: false,
        }
      }))
    }
  },[])

  if(!robotId) return null
  return (
    <DraggableDialog widget="chart_tools_widget">
      <Menu
        title="Chart Tools"
        items={options}/>
    </DraggableDialog>
  )
}
