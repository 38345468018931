import { useUpdateRate } from './useUpdateRate'
import { useAPI } from './useAPI'
import { ReadRobotData,RobotDataService } from '../api'

export const useLatestData = (robotId:string) => {
  const [REFETCH_RATE] = useUpdateRate()
  return useAPI<ReadRobotData>(
    `latestData - ${robotId}`,
    () => RobotDataService.latestData(robotId),
    REFETCH_RATE,
    !!robotId, 
  )
}
