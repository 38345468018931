//@ts-nocheck
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import styled from 'styled-components'
import { useChartModal } from '../hooks/useChartModal'
import DownloadIconSVG from '../icons/Download.svg'
import MenuIconSVG from '../icons/Menu.svg'
import SettingsIconSVG from '../icons/Settings.svg'
import dayjs from 'dayjs'

export type ChartProps = {
  css?: string 
  // options: { chart: { type: string; }; title: { text: string; }; series: { data: number[] }[]};
  data?:{[key: string]: number[]}
  label?:string
}

export const Chart = ({ css,data,label,number }: ChartProps):JSX.Element => {
  const [,setShow] = useChartModal()

  if(!Object.keys(data || {}).length) return null
  const formatData = (data:unknown) => { 
    const series:any[] = []
    const keys = Object.keys(data)
    const values = Object.values(data)
    
    keys.forEach((key,index) => series.push({
      data: values[index].map(v => ([v.timestamp.getTime(),v.value])),
      name: key,
      colorIndex: index,
    }))
    // is there a shorter way of doing this?
    return series
  }
  
  const options = {
    chart: { type: `line` },
    yAxis: { labels: { formatter: v => Number(v.value).toFixed(6) } },
    xAxis: {
      type: `datetime`,
      labels: { formatter: v => dayjs(v.value).format(`mm:ss`) },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          states: { hover: { enabled: false } },
        },
      },
    },
    tooltip: { enabled: false },
    allowPointSelect: false,
    series: formatData(data),
  }

  const handleDownload = () => {
    let csv = ``
    csv += Object.keys(data).join(`,`)
    for(let i = 0;i < data[Object.keys(data)[0]].length;i++)
      csv += `\n` + Object.keys(data).map(key => data[key][i]).join(`,`)
    
    const blob = new Blob([csv],{ type: `text/csv` })
  
    const a = document.createElement(`a`)
    a.download = `download.csv`
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent(`click`,{
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  return (
    <Container css={css}>
      <Header>
        <Label>{label}</Label>
        <MenuItems>
          <Download onClick={handleDownload} src={DownloadIconSVG} />
          <Settings onClick={() => setShow(number)} src={SettingsIconSVG} />
          <MoreOptions onClick={() => console.log(`Menu`)} src={MenuIconSVG} />
        </MenuItems>
      </Header>
      <ChartConatiner className="chart-container">
        <HighchartsReact 
          containerProps={{ style: { height: `100%` } }}
          highcharts={Highcharts}
          options={options} />
      </ChartConatiner>
    </Container>
  )
}

const Container = styled.div<{css?: string}>`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #041d24;

  & .highcharts-background {
    fill: #041d24;
  }
  & .highcharts-axis-line {
    display: none;
  }
  & .highcharts-yaxis-grid > path {
    stroke: #152c36;
  }

  & .highcharts-xaxis-grid > path {
    stroke: #152c36;
    /* display: none; */
  }
  & .highcharts-xaxis-grid > path:last-child,
  & .highcharts-xaxis-grid > path:nth-last-child(2){
    display: none;
  }
  & 
  .highcharts-axis-title,
    .highcharts-credits,
    .highcharts-title
     {
    display: none;
  }
  & .highcharts-tick {
    stroke: #152c36;
  }
  & .highcharts-series-0 path:nth-child(1) {
    stroke: #8853ff;
  }
  & .highcharts-series-1 path:nth-child(1) {
    stroke: #9fe4fa;
  }
  & .highcharts-legend-item>text{
    fill:white !important;
  }
${p => p.css}
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .3rem 2rem;
  box-sizing:border-box;
  background-color: #03181d;
  color: #fbfbfb;
`
const Label = styled.h2`
  font-size: 1rem;
  margin: 0;
`
const MenuItems = styled.div`
  display: flex;
  gap: .8rem;
  & > *{
    padding: 5px;
    cursor: pointer;
  }
  & > *:hover{
    opacity: 70%;
  }

`
const Download = styled.img``
const Settings = styled.img``
const MoreOptions = styled.img``

const ChartConatiner = styled.div`
  padding: 1rem 2rem 0 0;
  box-sizing:border-box;
  height:100%;
`
