import { useIndoorRobotId } from './useIndoorRobotId'
import { getDataPoint } from './../utils/getDataPoint'
import { useLatestData } from './useLatestData'

export const useRobotLatLng = (robotId:string) => {
  const { data } = useLatestData(robotId)
  const indoorRobotId = useIndoorRobotId()
  if(!robotId) return { lat: 25.115685022848357,lng: 55.4454363549650 }
  const lat = indoorRobotId === robotId
    ? getDataPoint(data,[`vehicle_odometry`,`virtual_lat`])
    : getDataPoint(data,[`gps_location`,`latitude`]) 
  const lng = indoorRobotId === robotId
    ? getDataPoint(data,[`vehicle_odometry`,`virtual_lng`])
    : getDataPoint(data,[`gps_location`,`longitude`])
  return { lat,lng }
}
