import React from 'react'
import { useRef } from 'react'
import styled from 'styled-components'

export type ButtonProps = {
  icon?:string
  border?:boolean
  onClick?: () => void
  children?: React.ReactNode
  css?: string;
  alert?: boolean
};

export function Button({ border,css,children,onClick,icon,alert = false }: ButtonProps):JSX.Element {
  const moving = useRef(false)
  const mouseDown = useRef(false)

  return (
    <StyledButton 
      alert={alert}
      css={css}
      border={border}
      onMouseMove={() => {
        if(!mouseDown.current) return
        setTimeout(() => moving.current = true,20)
      }}
      onMouseDown={() => {
        if(window.location.pathname === `/`) mouseDown.current = true 
      }}
      onMouseUp={() => {
        mouseDown.current = false
        setTimeout(() => moving.current = false,100)
      }}
      onClick={() => {
        if(!moving.current) onClick?.()
      }}
      icon={icon}>
      <Wrapper>
        {icon && 
        <IconWrapper>
          <Icon src={icon}/>
        </IconWrapper>
        }
        <Label className="label"> {children} </Label>
      </Wrapper>
      {
        border && <CornersContainer alert={alert}>
          <TopRight />
          <TopLeft />
          <BottomRight />
          <BottomLeft />
        </CornersContainer>
      }
    </StyledButton>
  )
}

const StyledButton = styled.button<{icon?:string,alert?:boolean,css?:string,border?:boolean}>`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  box-sizing:border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  height: 40px;
  color: #ffffff;
  background: none;
  appearance: none;
  user-select: none;
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid ${p => p.border ? `#7e9ea7` : `transparent`};
  /* box-shadow: 0px 0px 2px 0px #617E86, inset 0px 0px 2px 0px #617E86; */
  transition: all .1s;

  ${({ alert }) => alert && `
    border: 1px solid #FF4A3B;
    color: #FF4A3B;
  `}

  ${({ icon }) => icon && 
    `
      padding: 0 .6rem;
      justify-content: flex-start;
    `
}
  &:hover {
    /* border-color: #99bdc7;
    box-shadow: 0px 0px 4px 0px #617E86, inset 0px 0px 4px 0px #617E86; */
  }

  ${p => p.css}
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const IconWrapper = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const Label = styled.div`
  margin-left: .6rem;
`
const Icon = styled.img`
  height: 20px;
  width: 20px;
`
const CornersContainer = styled.div<{alert:boolean}>`
  ${({ alert }) => alert && 
  `
    & div {
      background-color: #FF4A3B 
    }
  `}
`
const TopRight = styled.div`
  position: absolute;
  left: -3px;
  top: -3px;
  width: 5px;
  height: 5px;
  background-color: #8ecbdd;
  border-radius: 1px;
`
const TopLeft = styled.div`
  position: absolute;
  right: -3px;
  top: -3px;
  width: 5px;
  height: 5px;
  background-color: #8ecbdd;
  border-radius: 1px;
`
const BottomRight = styled.div`
  position: absolute;
  left: -3px;
  bottom: -3px;
  width: 5px;
  height: 5px;
  background-color: #8ecbdd;
  border-radius: 1px;
`
const BottomLeft = styled.div`
  position: absolute;
  right: -3px;
  bottom: -3px;
  width: 5px;
  height: 5px;
  background-color: #8ecbdd;
  border-radius: 1px;
`
