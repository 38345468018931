import { atom,useAtom } from 'jotai'

export const routeToolsAtom = atom([
  {
    id: 0,
    label: `Hide/Show Router Planner`,
    active: true,
  },
  {
    id: 1,
    label: `Upload Route`,
  },
  {
    id: 2,
    label: `Clear Route`,
  },
])

export const useRouteTools = () => {
  return useAtom(routeToolsAtom)
}
