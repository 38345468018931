import DraggableDialog from './DraggableDialog'
import { MainMenu } from './MainMenu'

export function DraggableMainMenu():JSX.Element {

  return (
    <DraggableDialog widget="menu_widget"> 
      <MainMenu/>
    </DraggableDialog>
  )
}
