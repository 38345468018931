import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const ProtectedRoute = ({ children }:{children:ReactNode}):JSX.Element => {
  const [{ token }] = useAuth()
  if(!token) return (
    <>
      {children}
    </>
  )
  return <Navigate to="/" />
}
