import styled from 'styled-components'
import { useChartTools } from '../hooks/useChartTools'
import { usePath } from '../hooks/usePath'
import { useRouteTools } from '../hooks/useRouteTools'
import { MissionMarker } from './MissionMarker'
import { Polyline } from './Polyline'

export const RouteToolLayer = () => {
  const [path] = usePath()
  const [routeTools] = useRouteTools()
  const [chartTools] = useChartTools()
  if(!routeTools[0].active || !chartTools[0].active) return null
  return (
    <Container>
      <Polyline/>
      {path.map((waypoint,index) => {
        return (
          <MissionMarker 
            key={index}
            number={index}
            waypoint={waypoint}/>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`
