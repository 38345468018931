//@ts-nocheck
import styled from 'styled-components'
import { useRobotId } from '../hooks/useRobotId'

export interface TableProps {
  data?: unknown[]
  columns?: unknown[]
  css?: string
}

export function Table({ css,columns,data }: TableProps):JSX.Element {
  const [robotId] = useRobotId()

  if(!data.length) return null

  return (
    <Container css={css}>
      <table>
        <thead>
          <tr>
            {columns.map((column:any,index:number) => (
              <th key={index} style={{ textAlign: `center` }}>
                {/* <Value> */}
                {column.label}
                {/* </Value> */}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...data].map((row:any,index:number) => {
            return (
              <Tr key={index} selected={row.id === robotId}>
                {columns.map((column:any,index:number) => {
                  const { key,format,onClick = () => {} } = column
                  return (
                    <td key={index} onClick={() => onClick(row)}>
                      {/* <Value> */}
                      {format ? format(row[key]) : row[key]} 
                      {/* </Value> */}
                    </td>
                  )
                })}
              </Tr>
            )
          })}
        </tbody>
      </table>
    </Container>
  )
}


const Container = styled.div<TableProps>`
  overflow-y: auto;
  /* min-height:20vh;
  max-height:20vh; */
  height:100%;
  max-height:100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  box-sizing: border-box;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  & * {
    /* box-sizing: border-box; */
  }
  & table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    background-color: #011c23;
    overflow: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  & thead {
    /* width: 100%; */
    background-color: #00080b;
    color: #e6eaeb;
    font-size: .8rem;
    text-transform: uppercase;
    border-bottom: 1px solid #0d2932;
    position: sticky;
    top: 0;
    z-index:100;
  }
  & th {
    padding: 1.2rem 2rem;
    text-align: left;
    cursor: pointer;
    position: sticky;
    top: 0;
  }
  & tbody {
    margin: 0 3rem;
    & tr:hover {
      background: rgb(52,94,96);
      background: linear-gradient(0deg, #345e60ad 0%, rgba(69,116,131,1) 50%, #345e6c90 100%);
      color: #b5bdc0;
    }
    & td {
      padding:2vh 0;
    }
  }
  & tr {
    font-size: .9rem;
    border-bottom: 1px solid #0d2932;
    color: #c1cdd0;
    text-transform: uppercase;
    font-weight: 600;
    cursor: default;
  }
  ${p => p.css}
  `
const Tr = styled.tr`
  background: ${p => p.selected ? `linear-gradient(0deg, rgba(52,94,96,1) 0%, rgba(69,116,131,1) 50%, rgba(52,94,108,1) 100%);` : ``};
  color: #b5bdc0;
`
