import styled from 'styled-components'

export interface NotificationBoxProps {
  css?: string
  label?:string
  icon?: string
}

export function NotificationBox({ css,label,icon }: NotificationBoxProps):JSX.Element {
  return (
    <Container css={css}>
      {icon && <IconWrapper> <Icon src={icon} /> </IconWrapper>}
      <Notification>{label}</Notification>
    </Container>
  )
}

const Container = styled.div<NotificationBoxProps>`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60px;
  padding: 0 20px 0 20px;
  background-color: #fff;
  opacity:1;
  font-size: .9rem;
  border-radius: 50px;
  
  ${p => p.css}
`
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  color: #9be5fd;
`
const Icon = styled.img`
  width: 40px;
`
const Notification = styled.p`
  color: #5e5e5e;
  margin: 0;
`
