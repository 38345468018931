import { useOccupancyGridRobotId } from '../hooks/useOccupancyGridId'
import { ImageLayer } from './ImageLayer'
import { MissionLayer } from './MissionLayer'
import { NavigationStackLayer } from './NavigationStackLayer'
import { OdometryOverlayLayer } from './OdometryOverlayLayer'
import { RobotLayer } from './RobotLayer'
import { RouteToolLayer } from './RouteToolLayer'

export const Map = () => {
  const [occupancyGrid] = useOccupancyGridRobotId()
  return (
    <>
      <MissionLayer/>
      <RobotLayer/>
      <RouteToolLayer/>
      <NavigationStackLayer/>
      <OdometryOverlayLayer/>
      {occupancyGrid && <ImageLayer/>}
    </>
  )
}
