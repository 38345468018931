//@ts-nocheck
import { ReactNode } from 'react'
import styled from 'styled-components'
import modeIcon from '../icons/Mode.svg'
import headingIcon from '../icons/Heading.svg'
import speedIcon from '../icons/Speed.svg'
import batteryIcon from '../icons/Battery.svg'
import latencyIcon from '../icons/Latency.svg'
import signalIcon from '../icons/Signal.svg'
import positionIcon from '../icons/Position.svg'
import missionStatusIcon from '../icons/mission-status-icon.svg'
import userIcon from '../icons/User.svg'
import { useRobotId } from '../hooks/useRobotId'
import { rotationFromQuaternion } from '../utils/rotationFromQuaternion'
import { degToDMS } from '../utils/degToDMS'
import { useLatestData } from '../hooks/useLatestData'
import { getDataPoint } from '../utils/getDataPoint'
import { useIndoorRobotId } from '../hooks/useIndoorRobotId'

export const OFFSET_INIT_VAL : number = 24 * 60 * 60
let tsOffsetDict = {}

export interface MetricProps {
  name:string
  children?: ReactNode;
  css?: string
}

export function getRobotTsOffset(robotId)
{
  if (Object.prototype.hasOwnProperty.call(tsOffsetDict,robotId) === false)
    tsOffsetDict[robotId] = OFFSET_INIT_VAL

  return tsOffsetDict[robotId]
}

export function Metric({ name,css = `` }: MetricProps):JSX.Element {
  const [robotId] = useRobotId()
  const { data: latest } = useLatestData(robotId)
  const isIndoorMode = useIndoorRobotId()
  
  let tsOffset = getRobotTsOffset(robotId)

  const x = getDataPoint(latest,[`vehicle_odometry`,`twist`,`twist`,`linear`,`x`]) || 0
  const y = getDataPoint(latest,[`vehicle_odometry`,`twist`,`twist`,`linear`,`y`]) || 0
  const z = getDataPoint(latest,[`vehicle_odometry`,`twist`,`twist`,`linear`,`z`]) || 0

  const values = {
    battery: getDataPoint(latest,[`battery_level`,`percentage`]),
    lat: getDataPoint(latest,[`gps_location`,`latitude`]),
    lng: getDataPoint(latest,[`gps_location`,`longitude`]),
    x: getDataPoint(latest,[`vehicle_odometry`,`pose`,`pose`,`position`,`x`]),
    y: getDataPoint(latest,[`vehicle_odometry`,`pose`,`pose`,`position`,`y`]),
    heading: getDataPoint(latest,[`vehicle_odometry`,`pose`,`pose`,`orientation`]),
    speed: Math.sqrt(x * x + y * y + z * z) * 3.6,
    mode: getDataPoint(latest,[`robot_state_diagnostic`])?.status?.find(s => s.name === `operation_mode`)?.message,
    latency: getDataPoint(latest,[`diagnostics_connectivity`,`latency`]),
    signal: getDataPoint(latest,[`diagnostics_connectivity`,`signal`]),
    missionStatus: getDataPoint(latest,[`mission`,`status`]),
    controlledBy: latest?.robot?.controlled_by,

    modeTs: getDataPoint(latest,[`robot_state_diagnostic`,`header`,`stamp`,`secs`]),
    headingTs: getDataPoint(latest,[`vehicle_odometry`,`header`,`stamp`,`secs`]),
    positionTs: getDataPoint(latest,[`gps_location`,`header`,`stamp`,`secs`]),
    speedTs: getDataPoint(latest,[`vehicle_odometry`,`header`,`stamp`,`secs`]),
    batteryTs: getDataPoint(latest,[`battery_level`,`header`,`stamp`,`secs`]),
    latencyTs: getDataPoint(latest,[`diagnostics_connectivity`,`header`,`stamp`,`secs`]),
  }

  let isOnline = latest?.robot?.status === `online`
  let robotTs = values.speedTs
  
  let modeAge = `-`
  let headingAge = `-`
  let positionAge = `-`
  let speedAge = `-`
  let batteryAge = `-`
  let latencyAge = `-`
  
  function capper(val : number,upperCap : number = 99.9) : number {
    return Math.min(Math.abs(val),upperCap).toFixed(1)
    // return val
  }
    
  if (isOnline && robotTs && (tsOffset >= OFFSET_INIT_VAL)) {
    let diff = robotTs - Date.now() / 1000
    if (Math.abs(diff) < OFFSET_INIT_VAL)
    {
      tsOffset = diff
      tsOffsetDict[robotId] = diff
    }
  }

  
  if (tsOffset < OFFSET_INIT_VAL)
  {
    let uiTimeOffsetted = Date.now() / 1000 + tsOffset
  
    modeAge = values.modeTs ? capper(uiTimeOffsetted - values.modeTs) : `-`
    headingAge = values.headingTs ? capper(uiTimeOffsetted - values.headingTs) : `-`
    positionAge = values.positionTs ? capper(uiTimeOffsetted - values.positionTs) : `-`
    speedAge = values.speedTs ? capper(uiTimeOffsetted - values.speedTs) : `-`
    batteryAge = values.batteryTs ? capper(uiTimeOffsetted - values.batteryTs) : `-`
    latencyAge = values.latencyTs ? capper(uiTimeOffsetted - values.latencyTs) : `-`
  }

  const formatted = {
    battery: values.battery ? `${Number(values.battery || ``).toFixed()} %` : `-`,
    lat: values.lat ? `N ${degToDMS(values.lat) || ``}` : `-`,
    lng: values.lng ? `E ${degToDMS(values.lng) || ``}` : ``,
    x: typeof values.x === `number` ? `X: ${Number(values.x).toFixed(1)} m` : `-`,
    y: typeof values.y === `number` ? `Y: ${Number(values.y).toFixed(1)} m` : `-`,
    heading: values.heading ? `${Number(rotationFromQuaternion(values.heading)).toFixed(1)}°` : `-`,
    speed: values.speed ? `${Number(values.speed || 0).toFixed(2) || ``}` : `-`,
    // mode: values.mode ? `${{ '0': `Charging`,'1': `En Route`,'2': `Waiting for commands` }[values.mode] || values.mode}` : `-`,
    mode: values.mode ? values.mode : `-`,
    latency: values.latency ? Number(values.latency || ``).toFixed() : `-`,
    signal: values.signal || `-`,
    missionStatus: values.missionStatus || `-`,
    controlledBy: values.controlledBy || `-`,
  }

  const age = {
    lat: `(${positionAge} s)`,
    lng: ``,
    x: ``,
    y: ``,
    mode: `(${modeAge} s)`,
    heading: `(${headingAge} s)`,
    speed: `(${speedAge} s)`,
    battery: `(${batteryAge} s)`,
    latency: `(${latencyAge} s)`,
    signal: ``,
    missionStatus: ``,
    controlledBy: ``,
  }

  const icon = {
    mode: modeIcon,
    heading: headingIcon,
    speed: speedIcon,
    battery: batteryIcon,
    latency: latencyIcon,
    signal: signalIcon,
    position: positionIcon,
    lowBattery: batteryIcon,
    missionStatus: missionStatusIcon,
    controlledBy: userIcon,
  }[name]

  const title = {
    mode: `Mode`,
    heading: `Heading`,
    speed: `Speed (Km/Hr)`,
    battery: `Battery Level`,
    latency: `Latency (ms)`,
    signal: `Signal Strength`,
    position: `Position`,
    lowBattery: `Low Battery Level`,
    missionStatus: `Mission Status`,
    controlledBy: `Controlled by`,
  }[name]

  return (
    <Container css={css}>
      <IconWrapper>
        <Icon src={icon}/>
      </IconWrapper>
      <InfoWrapper>
        <Title>{title}</Title>
        {name !== `position` && <DataWrapper><Data>{formatted[name]}</Data><DataAge>{age[name]}</DataAge></DataWrapper>}
        {name === `position` && <DataWrapper><Data>{isIndoorMode ? formatted.x : formatted.lat}</Data><DataAge>{isIndoorMode ? age.x : age.lat}</DataAge></DataWrapper>}
        {name === `position` && <DataWrapper><Data>{isIndoorMode ? formatted.y : formatted.lng}</Data><DataAge>{isIndoorMode ? age.y : age.lng}</DataAge></DataWrapper>}
      </InfoWrapper>
    </Container>
  )
}

const Container = styled.div<{css:string}>`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 0.3rem 2rem;
  width: 100%;
  gap: 1.5rem;
  ${p => p.css}
`
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  & *{
    color: #82bfd2;
  }
`
const Icon = styled.img`
  height:20px;
  width:20px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-drag: none;
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
`
const Title = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: #82bfd2;
`
const DataWrapper = styled.div`
  display: flex;
`
const Data = styled.p`
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: start;
  white-space: pre;
  flex: 0 0 100px;
`
const DataAge = styled.p`
margin: 0;
font-size: 1.1rem;
font-weight: 600;
text-align: end;
white-space: pre;
flex: 0 0 75px;
`
