import styled from 'styled-components'
import { MenuView } from './MenuView'
import FleetStatusIcon from '../icons/FleetStatus.svg'
import { Charts } from './Charts'
import { FleetStatusTable } from './FleetStatusTable'


export interface FleetStatusProps {
  css?: string
}

export function FleetStatus({ css }: FleetStatusProps):JSX.Element {
  return (
    <Container css={css}>
      <MenuView
        icon={FleetStatusIcon}
        css={`
        & > div:nth-child(1) > div:nth-child(1) {
          transform: translate(-40%,-50%) rotate(25deg);
        }
        `}>
        <Children>
          <FleetStatusTable/>
          <Charts/>
        </Children>
      </MenuView>
    </Container>
  )
}

const Container = styled.div<FleetStatusProps>`
  ${p => p.css}
  &::-webkit-scrollbar {
    display: none;
  }
`
const Children = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow:1;
  height:100%;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; 
  scrollbar-width: none; 
`
