import { useUpdateRate } from './useUpdateRate'
import { RobotsService } from '../api'
import { useAPI } from './useAPI'
import { useRobotId } from './useRobotId'

export const useRobot = () => {
  const [robotId] = useRobotId()
  const [updateRate] = useUpdateRate()
  const answer = useAPI(
    `robots - ${robotId}`,
    () => RobotsService.robotsRead(robotId),
    updateRate,
    !!robotId)
  return answer
}
