import { ReactNode } from 'react'
import styled from 'styled-components'

export interface CardProps {
  children?: ReactNode;
  css?: string
}

export function Card({ children,css }: CardProps):JSX.Element {
  return (
    <StyledCard css={css}>
      {children}
    </StyledCard>
  )
}

const StyledCard = styled.div<CardProps>`
  background: rgb(3, 22, 28, 0.8);
  color: #fff;
  ${p => p.css}
`
