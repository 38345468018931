import { useUpdateRate } from './useUpdateRate'
import { RobotsService } from './../api/services/RobotsService'
import { useAPI } from './useAPI'

export const useRobots = () => {
  const [updateRate] = useUpdateRate()
  const answer = useAPI(
    `robotsAtom`,
    RobotsService.robotsList,
    updateRate)
  return answer
}

