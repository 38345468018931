/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClearMission } from '../models/ClearMission';
import type { Mission } from '../models/Mission';
import type { NewMission } from '../models/NewMission';
import type { NoContent } from '../models/NoContent';
import type { UpdateMission } from '../models/UpdateMission';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MissionService {

    /**
     * @param data
     * @returns NoContent
     * @throws ApiError
     */
    public static clearMission(
        data: ClearMission,
    ): CancelablePromise<NoContent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/mission/clear',
            body: data,
        });
    }

    /**
     * @param data
     * @returns Mission
     * @throws ApiError
     */
    public static newMission(
        data: NewMission,
    ): CancelablePromise<Mission> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/mission/create',
            body: data,
        });
    }

    /**
     * @param page A page number within the paginated result set.
     * @returns Mission
     * @throws ApiError
     */
    public static myMission(
        page?: number,
    ): CancelablePromise<Mission> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/mission/my_mission',
            query: {
                'page': page,
            },
        });
    }

    /**
     * @param missionId
     * @returns Mission
     * @throws ApiError
     */
    public static missionStatus(
        missionId: string,
    ): CancelablePromise<Mission> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/mission/status/{mission_id}',
            path: {
                'mission_id': missionId,
            },
        });
    }

    /**
     * @param data
     * @returns NoContent
     * @throws ApiError
     */
    public static updateMission(
        data: UpdateMission,
    ): CancelablePromise<NoContent> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/mission/update/',
            body: data,
        });
    }

}
