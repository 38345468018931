import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AutoLogin } from './components/AutoLogin'

const root = ReactDOM.createRoot(
  document.getElementById(`root`) as HTMLElement,
)
root.render(
  <React.StrictMode>
    <AutoLogin>
      <App/>
    </AutoLogin>
  </React.StrictMode>,
)
