import { getRobotColor } from '../utils/getRobotColor'
import styled from 'styled-components'
import { capitalCase } from 'change-case'
import { useRobotId } from '../hooks/useRobotId'
import { useRobotLatLng } from '../hooks/useRobotLatLng'


export const RobotListItem = ({ robot }:{robot:any}) => {
  const [robotId,setRobotId] = useRobotId()
  const color = getRobotColor(robot)
  const { lat: latitude,lng: longitude } = useRobotLatLng(robot.id)
  const svg = `
<svg xmlns="http://www.w3.org/2000/svg" width="24.17" height="24" viewBox="0 0 24.17 24">
  <g id="Robot_Icon" data-name="Robot Icon" transform="translate(-983 -493)">
    <rect id="Rectangle_248" data-name="Rectangle 248" width="24" height="24" transform="translate(983 493)" fill="#c34848" opacity="0"/>
    <path id="Robot_Icon-2" data-name="Robot Icon" d="M5.592,3.97A2.631,2.631,0,0,0,2.966,6.6V9.723a2.631,2.631,0,0,0,2.626,2.63H18.579A2.631,2.631,0,0,0,21.2,9.723V6.6a2.631,2.631,0,0,0-2.626-2.63H5.592m0-2.97H18.579A5.6,5.6,0,0,1,24.17,6.6V9.723a5.6,5.6,0,0,1-5.592,5.6H5.592A5.6,5.6,0,0,1,0,9.723V6.6A5.6,5.6,0,0,1,5.592,1Z" transform="translate(983 497)" fill="${color}"/>
  </g>
</svg>
  `
  const handleClick = () => {
    if(latitude !== undefined && longitude !== undefined){
      const center = new google.maps.LatLng(latitude,longitude)
      window?.map?.panTo(center)
    }
    setRobotId(robot.id)
  }
  return (
    <Container 
      selected={robotId === robot.id}
      onClick={handleClick}>
      <Icon src={`data:image/svg+xml;charset=UTF-8;base64,` + btoa(svg)} />
      <Title>{capitalCase(robot?.name || ``)}</Title>
    </Container>
  )
}

const Container = styled.div<{selected:boolean}>`
  background-color:${p => p.selected ? `#00303b` : ``};
  display: flex;
  border-bottom: 1px solid rgba(143, 189, 206, 0.2);
  padding: 1rem 0 1rem 1.7rem;
  cursor: pointer;
`
const Title = styled.p`
  margin: 0;
  color:#fff;
  font-size: 1rem;
`
const Icon = styled.img`
  height:18px;
  margin-right: 1rem;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-drag: none;
`
