import { useEffect } from 'react'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { usePath } from '../hooks/usePath'

export const Polyline = () => {
  const [path,setPath] = usePath()
  const [fullScreenMode] = useFullScreenMode()

  const line = new google.maps.Polyline({ 
    path,
    strokeColor: `#fff`,
    strokeOpacity: 1,
    strokeWeight: 2,
  })
  line.setEditable(true)
  line.addListener(`mouseup`,() => {
    const path = line.getPath() as any
    var newPath = []
    for (var i = 0; i < path.length; i++) 
      newPath.push({
        lat: path.getAt(i).lat(),
        lng: path.getAt(i).lng(),
      })
    setPath(newPath)
  })

  const handleClick = (e:any) => {
    setPath([...path,{ lat: e.latLng.lat(),lng: e.latLng.lng() }])
  }

  useEffect(() => {
    let listener:any
    
    const wait = () => {
      setTimeout(() => {
        if(!window.map) wait()
        else {
          line.setMap(window.map)    
          listener = window.map.addListener(`click`,handleClick)
        }
      },0)
    }

    wait()

    return () => {
      if(listener)listener.remove()
      line?.setMap?.(null) 
    }
  },[path,window.map,fullScreenMode])

  return null
}
 
