import styled from 'styled-components'
import { Button } from './Button'

export interface MenuProps {
  title?:string
  items?: {id:number,label:string,active?:boolean,enabled?:boolean,icon?:string,onClick?:(id:number)=>void}[]
  css?: string
}

export function Menu({ css,title,items }: MenuProps):JSX.Element {

  return (
    <Container 
      onMouseMove={(e:any) => e.stopPropagation()}
      css={css}>
      <Wrapper>
        {title && 
        <Header>
          <Title>{title}</Title>
        </Header>
        }
        <MenuItems>
          {items?.map(({ id,label,icon,active,enabled,onClick = () => {} },index) => {
            let style = ``
            let border = false
            if (active) border = true
            if (!active && !enabled) style = `opacity: 40%;`
            return <Button 
              css={`${style} justify-content:flex-start;`}
              // onMouseMove={(e:any) => e.stopPropagation()}
              border={border}
              icon={icon ? icon : ``} 
              key={index}
              onClick={() => onClick(id)}>
              {label}
            </Button>},
          )}
        </MenuItems>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div<MenuProps>`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
  width: 250px;
  ${p => p.css}
`
const Wrapper = styled.div`
  padding: 1rem;
`
const Header = styled.div`
  padding-bottom: 1rem;
`
export const Title = styled.div`
  padding-left: .5rem;
  font-weight:700;
  font-size:1.2rem;
  /* margin-bottom: 1rem; */
  color: #82bfd2;
`
const MenuItems = styled.div`
  display: flex;
  flex-direction: column;

  gap: .5rem;
  & > button {
    width: 100%;
  }
`
