import { useEffect,useRef } from 'react'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { useOccupancyGrid } from '../hooks/useOccupancyGrid'
import blankStyles from './blankMapStyles'
import mapStyles from './mapStyles'

export const ImageLayer = () => {
  const ref = useRef<any>()
  const data = useOccupancyGrid()
  const [fullScreenMode] = useFullScreenMode()

  useEffect(() => {
    const { grid_data,data: bounds } = data
    if(!grid_data) return 
    const url = `data:image/jpg;base64,` + grid_data
    ref.current = new google.maps.GroundOverlay(url,bounds)
    const wait = () => {
      setTimeout(() => {
        if(!window.map) wait()
        else {
          ref.current.setMap(window.map)    
          window.map.fitBounds(bounds)
          const zoom = window.map.getZoom()
          window.map.setOptions({ 
            styles: blankStyles,
            minZoom: zoom,
            restriction: { latLngBounds: bounds },
          })
        }
      },0)
    }
    wait()
    return () => {
      ref.current?.setMap?.(null) 
      window.map.setOptions({
        styles: mapStyles,
        minZoom: 0,
        restriction: null,
      })
    }
  },[data,window.map,fullScreenMode])

  return null
}

