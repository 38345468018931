import { useEffect,useRef } from 'react'
import { lightgreen } from '../colors'
import { useChartTools } from '../hooks/useChartTools'
import { useFullScreenMode } from '../hooks/useFullScreenMode'

export const OdometryOverlayPolyline = ({ path }:{path:{lat:number,lng:number}[]}) => {
  const [fullScreenMode] = useFullScreenMode()
  const [chartTools] = useChartTools()

  const ref = useRef<any>()

  useEffect(() => {
    ref.current = new google.maps.Polyline({ 
      path,
      strokeColor: lightgreen,
      strokeOpacity: 1,
      strokeWeight: 5,
    })
  },[])

  useEffect(() => {
    ref.current?.setPath(path)
  },[path])

  useEffect(() => {
    ref.current.setMap(chartTools[2].active ? window.map : null)
  },[chartTools[2].active])

  let timeout:any 

  useEffect(() => {

    const wait = () => {
      timeout = setTimeout(() => {
        if(!window.map) wait()
        else ref.current.setMap(window.map)    
      },0)
    }

    wait()

    return () => {
      ref.current?.setMap?.(null) 
      if(timeout) clearTimeout(timeout)
    }
  },[window.map,fullScreenMode])

  return null
}
 
