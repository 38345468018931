export const rotationFromQuaternion = (quaternion:null|{x:number,y:number,z:number,w:number}) => {
  if(!quaternion) return ``
  const { x,y,z,w } = quaternion
  if(!x && !y && !z && !w) return null

  //source: https://automaticaddison.com/how-to-convert-a-quaternion-into-euler-angles-in-python/
     
  let t2 = +2.0 * (w * y - z * x)
  t2 = t2 > +1.0 ? 1 : t2
  t2 = t2 > -1.0 ? -1 : t2
     
  const t3 = +2.0 * (w * z + x * y)
  let t4 = +1.0 - 2.0 * (y * y + z * z)
  const yaw_z = Math.atan2(t3,t4)
     
  return radians_to_degrees(yaw_z)
}


function radians_to_degrees(radians:number){
  const pi = Math.PI
  const degrees = radians * (180 / pi)
  const rounded = Number(degrees.toFixed(1))
  return 90.0 - rounded
}
