import { useEffect,useRef } from 'react'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { useHoveredRobot } from '../hooks/useHoveredRobot'
import { useRobotId } from '../hooks/useRobotId'
import { useRobotLatLng } from '../hooks/useRobotLatLng'
import { getRobotColor } from '../utils/getRobotColor'

declare global {
  interface Window {
    map: any;
  }
}

export const HaloMarker = ({ robot }:{robot:any}) => {
  const [fullscreenMode] = useFullScreenMode()
  const size = 141.445
  const [robotId,setRobotId] = useRobotId()
  const [hoveredRobot,setHoveredRobot] = useHoveredRobot()
  const { lat,lng } = useRobotLatLng(robot.id)
  const show = robotId === robot.id || hoveredRobot === robot.id
  const color = show
    ? getRobotColor(robot)
    : `transparent`
  const ref = useRef(new google.maps.Marker())
  ref.current.addListener(`mouseover`,() => setHoveredRobot(robot.id))
  ref.current.addListener(`mouseout`,(e:any) => {
    const x = e.domEvent.layerX - size / 2
    const y = e.domEvent.layerY - size / 2
    const amplitude = Math.sqrt(x * x + y * y)
    if(amplitude > size / 2 * 0.6) setHoveredRobot(`none`)
  })
  ref.current.addListener(`click`,() => setRobotId(robot.id))

  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${size}" height="${size}" viewBox="40 40 61 61"
  style="border-radius:50%;"
  >
  <defs>
    <linearGradient id="linear-gradient" x1="-0.384" y1="0.124" x2="1.122" y2="0.586" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="${color}" stop-opacity="0.02"/>
      <stop offset="1" stop-color="${show ? `#030f19` : `transparent`}"/>
    </linearGradient>
  </defs>
  <g id="Robot_Ready" data-name="Robot Ready" transform="translate(-842.282 -147.3)">
    <circle id="Ellipse_22" data-name="Ellipse 22" cx="50" cy="50" r="${hoveredRobot === robot.id ? 30 : 25}" transform="translate(842.982 208.181) rotate(-37)" stroke="${color}" stroke-width="1" fill="url(#linear-gradient)"/>
  </g>
  </svg>
`

  useEffect(() => {
    ref.current.setIcon({
      url: `data:image/svg+xml;charset=UTF-8;base64,` + btoa(svg),
      anchor: new google.maps.Point(70,70),
    })
  },[svg])

  useEffect(() => {
    if(typeof lat === `number` && typeof lng === `number`)ref.current.setPosition({ lat,lng })
  },[lat,lng])

  useEffect(() => {
    const wait = () => {
      setTimeout(() => {
        if(!window.map) wait()
        else ref.current.setMap(window.map)    
      },0)
    }
    wait()
    return () => ref.current.setMap(null) 
  },[window.map,fullscreenMode])

  return null
}
