import { useEffect } from 'react'
import styled from 'styled-components'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { useRobotId } from '../hooks/useRobotId'
import { useRobotLatLng } from '../hooks/useRobotLatLng'
import { useShowCamera } from '../hooks/useShowCamera'
import blankMapStyles from './blankMapStyles'
import mapStyles from './mapStyles'

export const MapContainer = () => {
  const [fullScreenMode] = useFullScreenMode()
  const [showCamera] = useShowCamera()
  const [robotId] = useRobotId()
  const { lat,lng } = useRobotLatLng(robotId)

  useEffect(() => {
    const wait = () => {
      setTimeout(() => {
        initMap()
      },100)
    }

    const initMap = () => {
      if(fullScreenMode && !showCamera) return
      const id = fullScreenMode ? `mini-map` : `main-map`
      const div = document.getElementById(id)
      if(!div) return wait()
      if(div)
        window.map = new google.maps.Map(div,{
          center: { lat,lng },
          zoom: fullScreenMode ? 16 : 17,
          styles: mapStyles,
          // styles: blankMapStyles,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          clickableIcons: false,
          disableDefaultUI: true,
        })
      
    }
    initMap()
  },[fullScreenMode,showCamera])

  // console.log(window.map?.__gm?.Ia?.id)

  if(fullScreenMode) return null
  return (
    <Container id="main-map"/>
  )
}

const Container = styled.div`
  display:flex;
  width:100%;
  height:100vh;
`
