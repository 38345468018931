export const joystickPayload = ({ x,y }:{x:number,y:number}) => {

  const output = {
    version: 1,
    type: `geometry_msgs.msg.Twist`,
    data: {
      linear: {
        x: y,
        y: 0,
        z: 0,
      },
      angular: {
        x: 0,
        y: 0,
        z: x,
      },
    },
  }

  // const base64output = btoa(output)

  // return base64output
  return JSON.stringify(output,null,2)
}
