//@ts-nocheck
import { useRoomId } from './useRoomId'
import { useEffect } from 'react'
import { useJanus } from './useJanus'
import { useVideoroomData } from './useVideoroomData'
import { useCameraStream } from './useCameraStream'
import { opaqueId } from '../config'
import { useAudioStream } from './useAudioStream'

let videoroom = null
let currentRoom = null

export const useVideoroomSubscriber = () => {
  const [videoroomData] = useVideoroomData()
  const [,setCameraStream] = useCameraStream()
  const [,setAudioStream] = useAudioStream()
  const [room] = useRoomId()
  const [janusStatus,janus] = useJanus()
  useEffect(() => {
    if(currentRoom !== room) {
      if(videoroom) videoroom.hangup()
      videoroom = null
    }
    if(janusStatus === `connected` && !videoroom && videoroomData){
      const { myid,private_id,streams } = videoroomData
      if(myid && private_id && streams.length)
        janus.attach({
          plugin: `janus.plugin.videoroom`,
          opaqueId,
          success(pluginHandle) {
            videoroom = pluginHandle
            videoroom.send({
              message: {
                request: `join`,
                display: `robo-ui-subscriber`,
                room,
                ptype: `subscriber`,
                streams,
                use_msid: true,
                private_id,
              }, 
            })
          },
          error(error) { console.error(`Error attaching plugin...`,error) },
          iceState(state) { },
          webrtcState(on) { },
          slowLink(uplink,lost,mid) { },
          onmessage(msg,jsep) {
            let event = msg.videoroom
            if(msg.error) console.log(msg.error)
            if(msg.leaving) unsubscribeFrom(msg.leaving)
            if(msg.unpublished) {
              if(msg.unpublished === `ok`) return videoroom.hangup() 
              unsubscribeFrom(msg.unpublished)
            }
            if(jsep) videoroom.createAnswer({
              jsep,
              tracks: [{ type: `data` }],
              success(jsep) { videoroom.send({ message: { request: `start`,room },jsep }) },
              error(error) { console.error(`WebRTC error:`,error) },
            })
          },
          onlocaltrack(track,on) { },
          onremotetrack(track,mid,on) {
            if(track.kind === `audio`) setAudioStream(new MediaStream([track]))
            if(track.kind === `video`) setCameraStream(new MediaStream([track]))
          },
          oncleanup() {},
        })
      return () => {
        setAudioStream(null)
        setCameraStream(null)
        // console.log({ videoroom })
        // if(videoroom) videoroom.send({ janus: `destroy` })
        // if(videoroom) videoroom.send({ message: { janus: `destroy` } })
        if(videoroom) videoroom.send({ message: { request: `unsubscribe` } })
        // if(videoroom) videoroom.hangup()
        videoroom = null
      }
    }
  },[janusStatus,videoroomData,room])
  return { status: `connected`,videoroom }
}

function unsubscribeFrom(id) {
  if(videoroom) videoroom.send({
    message: {
      request: `unsubscribe`,
      streams: [{ feed: id }],
    }, 
  })
}

// function leave() {
//   if(videoroom) videoroom.send({ message: { request: `leave` } })
// }
