import styled from 'styled-components'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { Video } from './video'

export const FullScreenMode = () => {
  const [fullScreenMode] = useFullScreenMode()
  if(!fullScreenMode) return null

  return (
    <Container>
      <Video/>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  position:absolute;
  object-fit:cover;
  width:100vw;
  height:100vh;
`
