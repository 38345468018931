import { useEffect,useRef } from 'react'
import { useFullScreenMode } from '../hooks/useFullScreenMode'
import { usePath } from '../hooks/usePath'
import pin from '../icons/Pin.svg'
import pinRemove from '../icons/PinRemove.svg'

declare global {
  interface Window {
    map: any;
  }
}

export const MissionMarker = ({ waypoint,number }:{waypoint:any,number:number}) => {
  const [path,setPath] = usePath()
  const [fullScreenMode] = useFullScreenMode()
  const { lat,lng } = waypoint
  const ref = useRef(new google.maps.Marker())
  ref.current.setLabel(String(number + 1))
  ref.current.setIcon({
    url: pin,
    anchor: new google.maps.Point(15,35),
  })
  ref.current.addListener(`mouseover`,() => {
    ref.current.setLabel(null)
    ref.current.setIcon({
      url: pinRemove,
      anchor: new google.maps.Point(15,35),
    })
  })
  ref.current.addListener(`mouseout`,() => {
    ref.current.setIcon({
      url: pin,
      anchor: new google.maps.Point(15,35),
    })
    ref.current.setLabel(String(number + 1))
  })

  ref.current.addListener(`click`,() => {
    const newPath = [...path]
    newPath.splice(number,1)
    setPath(newPath)
  })

  useEffect(() => {
    if(lat && lng)ref.current.setPosition({ lat,lng })
  },[lat,lng])

  useEffect(() => {
    const wait = () => {
      setTimeout(() => {
        if(!window.map) wait()
        else ref.current.setMap(window.map)    
      },0)
    }
    wait()
    return () => ref.current?.setMap?.(null) 
  },[window.map,fullScreenMode])

  return null
}
